import React from "react";
import { Header } from "../Compenents/Header";
import { Footer } from "../Compenents/Footer";
import HomeBody from "../Compenents/HomeBody";
import { Helmet } from "react-helmet"

export const Home = () => {
  return (
    <>
 <Helmet>
        <title>Home Page</title>
      </Helmet>

      <Header />
      <HomeBody />
      <Footer />
    </>
  );
};
