import React from 'react'

function HomeBody() {
  return (


    <div className="Hero__hero___18d6Y">
  <div className="Hero__curve___2Z6rL Hero__transparent___1gcCM">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 400 100"
      preserveAspectRatio="none"
    >
      <path
        d="M0 0 C50 0, 150 50, 200 50 S350 0, 400 10 L400 100 L0 100 Z"
        fill="white"
      />
    </svg>
  </div>
  <div className="Hero__curve___2Z6rL Hero__transparent___1gcCM">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 400 100"
      preserveAspectRatio="none"
    >
      <path d="M0 100 Q200 -50, 400 100 Z" fill="white" />
    </svg>
  </div>
  <div className="Hero__curve___2Z6rL">
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 400 100"
      preserveAspectRatio="none"
    >
      <path
        d="M0 50 C50 110, 110 110, 200 50 S300 0, 400 90 L400 100 L0 100 Z"
        fill="white"
      />
    </svg>
  </div>
  <div className="Hero__content___2i4qn">
    <div className="Hero__titleContainer___oBqUg">
      <h1 data-cy="heroTitle" className="Hero__title___2viCH">
        Search for a stock to start your analysis
      </h1>
    </div>
    <p data-cy="heroSubtitle" className="Hero__subTitle___23GPg">
    Accurate information on 70K stocks and funds, including all the companies in the S&P500 index. See stock prices, news, financials, forecasts, charts and more.


    </p>
    <div data-cy="heroInputContainer" className="Hero__inputContainer___35Xem">
      <div className="Search__search___3Bcu4 Search__home___19K-z">
        <div
          data-cy="searchInputWrapper"
          className="Search__inputWrapper___3pAq9"
        >
          <input
            type="text"
            autoComplete="off"
            defaultValue=""
            placeholder="Enter your company or stock symbol..."
            tabIndex={1}
            name="search1"
            aria-label="Search Input"
          />
          <ul
            data-cy="suggestionsList"
            className="Suggestions__suggestions___3RkpY Suggestions__home___38N7T"
            style={{ height: 0, borderBottomWidth: 0, overflow: "hidden" }}
          />
          <input
            type="submit"
            className="versusButton inverted Search__compareBtn___3Kk3Y Search__landing___3lDdW"
            defaultValue="Search"
            tabIndex={1}
            value="Search"

          />
        </div>
      </div>
    </div>
  </div>
  <div className="Hero__button___3NWeB">
    <span className="Hero__angle___3b5j0">
      <span className="VsIcon__icon___3m84T" style={{ width: 40, height: 40 }}>
        <svg
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          width={32}
          height={32}
          viewBox="0 0 32 32"
        >
          <path d="M16 21.777l-10.013-10.869 0.733-0.685 9.28 10.073 9.28-10.073 0.733 0.685z" />
        </svg>
      </span>
    </span>
  </div>
  <div className="Hero__darkFadeMask___3wAiY" />
</div>



  )
}

export default HomeBody