import React from "react";
import { useState } from "react";
import { useEffect } from "react";

export const Header = () => {
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 90);
    });
  }, []);

  var button = "";
  if ((window.location.pathname !== "/")) {
    button  = "navigationContainer";
  } else if 
  ( !scroll)
  
  {
    button = "navigationContainer transparent";
  }

  else {
    button = "navigationContainer";
  }
  

  return (
    <header
className={button}
    >
      <div className="navBanner hide undefined">
        <div className="invisibleBox" />
        <div className="textContent">
          <a
            className="bannerText noBtn"
            href="undefined"
            target="_blank"
            rel="noopener noreferrer"
          />
        </div>
        <button className="bannerCloseButton" aria-label="Close Deal Banner">
          <svg
            stroke="currentColor"
            fill="currentColor"
            strokeWidth={0}
            version="1.1"
            viewBox="0 0 16 16"
            height={14}
            width={14}
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M15.854 12.854c-0-0-0-0-0-0l-4.854-4.854 4.854-4.854c0-0 0-0 0-0 0.052-0.052 0.090-0.113 0.114-0.178 0.066-0.178 0.028-0.386-0.114-0.529l-2.293-2.293c-0.143-0.143-0.351-0.181-0.529-0.114-0.065 0.024-0.126 0.062-0.178 0.114 0 0-0 0-0 0l-4.854 4.854-4.854-4.854c-0-0-0-0-0-0-0.052-0.052-0.113-0.090-0.178-0.114-0.178-0.066-0.386-0.029-0.529 0.114l-2.293 2.293c-0.143 0.143-0.181 0.351-0.114 0.529 0.024 0.065 0.062 0.126 0.114 0.178 0 0 0 0 0 0l4.854 4.854-4.854 4.854c-0 0-0 0-0 0-0.052 0.052-0.090 0.113-0.114 0.178-0.066 0.178-0.029 0.386 0.114 0.529l2.293 2.293c0.143 0.143 0.351 0.181 0.529 0.114 0.065-0.024 0.126-0.062 0.178-0.114 0-0 0-0 0-0l4.854-4.854 4.854 4.854c0 0 0 0 0 0 0.052 0.052 0.113 0.090 0.178 0.114 0.178 0.066 0.386 0.029 0.529-0.114l2.293-2.293c0.143-0.143 0.181-0.351 0.114-0.529-0.024-0.065-0.062-0.126-0.114-0.178z" />
          </svg>
        </button>
      </div>
      <div className="navigation">
        <div className="navLeft">
          <div className="logo ">
            <a href="/">
              <img src="/logo.svg" alt="versus logo" />
            </a>
          </div>

          {window.location.pathname !== "/" && <div className="navSearch">
              <div className="searchIcon">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 16, height: 16 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_loupe</title>
                    <path d="M28.55 27.325l-7.243-7.391c1.495-1.751 2.401-4.010 2.401-6.476 0-5.542-4.553-10.050-10.149-10.050s-10.15 4.509-10.15 10.050c0 5.541 4.553 10.049 10.149 10.049 2.563 0 4.901-0.953 6.689-2.511l7.23 7.378 1.073-1.049zM4.409 13.458c0-4.99 4.104-9.050 9.149-9.050s9.149 4.060 9.149 9.050c0 4.99-4.104 9.049-9.149 9.049s-9.149-4.059-9.149-9.049z" />
                  </svg>
                </span>
              </div>
              <span className="searchPlaceholder">Search</span>
              <input type="text" aria-label="Search Input" />
            </div>}

          
            
        
          <div className="navMenu">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 512 512"
              height={12}
              width={12}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M149.333 56v80c0 13.255-10.745 24-24 24H24c-13.255 0-24-10.745-24-24V56c0-13.255 10.745-24 24-24h101.333c13.255 0 24 10.745 24 24zm181.334 240v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm32-240v80c0 13.255 10.745 24 24 24H488c13.255 0 24-10.745 24-24V56c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24zm-32 80V56c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.256 0 24.001-10.745 24.001-24zm-205.334 56H24c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24zM0 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H24c-13.255 0-24 10.745-24 24zm386.667-56H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zm0 160H488c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H386.667c-13.255 0-24 10.745-24 24v80c0 13.255 10.745 24 24 24zM181.333 376v80c0 13.255 10.745 24 24 24h101.333c13.255 0 24-10.745 24-24v-80c0-13.255-10.745-24-24-24H205.333c-13.255 0-24 10.745-24 24z" />
            </svg>
            <a href="/fr/categories" className="navMenuTitle">
              Catégories
            </a>
          </div>
          <span className="mobileMenuBars">
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth={0}
              viewBox="0 0 448 512"
              color="white"
              style={{ color: "white" }}
              height={20}
              width={20}
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z" />
            </svg>
          </span>
          <div className="mainCategories">
            <a className="dealsLink" href="/fr/deals">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 448 512"
                name="dealsIcon"
                color="white"
                opacity="0.6"
                style={{ color: "white" }}
                height={14}
                width={14}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M323.56 51.2c-20.8 19.3-39.58 39.59-56.22 59.97C240.08 73.62 206.28 35.53 168 0 69.74 91.17 0 209.96 0 281.6 0 408.85 100.29 512 224 512s224-103.15 224-230.4c0-53.27-51.98-163.14-124.44-230.4zm-19.47 340.65C282.43 407.01 255.72 416 226.86 416 154.71 416 96 368.26 96 290.75c0-38.61 24.31-72.63 72.79-130.75 6.93 7.98 98.83 125.34 98.83 125.34l58.63-66.88c4.14 6.85 7.91 13.55 11.27 19.97 27.35 52.19 15.81 118.97-33.43 153.42z" />
              </svg>
              <span className="dealsLabel">Offres</span>
            </a>
            <a className="category" href="/fr/phone">
              <span className="catName">smartphones</span>
            </a>
            <a className="category" href="/fr/graphics-card">
              <span className="catName">cartes graphiques</span>
            </a>
            <a className="category" href="/fr/cpu">
              <span className="catName">processeurs</span>
            </a>
          </div>
        </div>
        <div className="navRight">
          <div className="navLang">
            <span className="currentLang">fr</span>
          </div>
          <div className="userMenuContainer">
            <div className="userIcon">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 448 512"
                color="white"
                style={{ color: "white" }}
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0 96 57.3 96 128s57.3 128 128 128zm89.6 32h-16.7c-22.2 10.2-46.9 16-72.9 16s-50.6-5.8-72.9-16h-16.7C60.2 288 0 348.2 0 422.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-41.6c0-74.2-60.2-134.4-134.4-134.4z" />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};
