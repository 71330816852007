import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "../Compenents/Header";
import { Footer } from "../Compenents/Footer";
import SingleBody from "../Compenents/SingleBody";
import { Helmet } from "react-helmet";
function Quote() {
  
  const [Data, setData] = useState([]);

  const location = useLocation();
  console.log("Path:", location.pathname.split("/")[2]);

  useEffect(() => {
    fetch(
      "https://api.forecast.ac/stock?&symbol=" +
        location.pathname.split("/")[2] +
        ""
    )
      .then((response) => {
        return response.json();
      })
      .then((data) => {
        setData(data[0]);
      });
  }, []);



  return (
    <>

<Helmet>
        <title>{Data.Name}</title>
      </Helmet>
        <Header />
        <SingleBody data={Data}/>
        <Footer />


    </>
  );
};



export default Quote