function SingleBody(props) {
 return (


    <div className="comparison Comparison__comparison___3Z_ez">
      <div id="imageContainer" className="imageContainer">
        <div className="images">
          <div className="namesContainer single">
            <div
              className="nameContainer"
              style={{ transform: "translateX(0%)" }}
            >
              <div className="scores">
                <div className="scoreContainer">
                  <div className="score  ">
                    <span
                      className="pointsText "
                      data-cy="mouchoScoreContainer"
                    >
                      <span>77</span>
                      <span className="points ">points</span>
                    </span>
                    <div className="slice  over50">
                      <div
                        className="bar "
                        style={{ transform: "rotate(277.2deg)" }}
                      />
                      <div className="fill " />
                    </div>
                  </div>
                </div>
              </div>
              <p>{props.data.Name}</p>
            </div>
          </div>
          <div className="priceContainer single hasPriceTags">
            <div
              className="priceTagContainerMobile"
              style={{ transform: "translateX(0%)" }}
            >
              <div className="pricetags vs rivalIdx0">
                <div className="wrapper">
                  <a
                    className="cell"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwQ0hYNlNEOTY%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9naSZ0aD0xJnBzYz0x"
                  >
                    <span className="currency">
                      <em>€</em>
                    </span>
                    <span className="natural">
                      <em>1 189</em>
                    </span>
                  </a>
                  <a
                    className="cell merchantLogo"
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwQ0hYNlNEOTY%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9naSZ0aD0xJnBzYz0x"
                  >
                    <img
                      alt="Apple iPhone 15 amazon_fr pricetag"
                      src="/img/amazon-logo.png"
                    />
                  </a>
                  <button className="cell arrow visible">
                    <svg
                      stroke="currentColor"
                      fill="currentColor"
                      strokeWidth={0}
                      viewBox="0 0 24 24"
                      height="1em"
                      width="1em"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path fill="none" d="M0 0h24v24H0V0z" />
                      <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                    </svg>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="picturesContainer single">
            <div
              className="pictureContainer"
              style={{ transform: "translateX(0%)" }}
            >
              <div className="imageAndTagsContainer">
                <div className="variantsContainer single">
                  <div className="rivalVariants">
                    <a className="variant" href="/fr/apple-iphone-15-128gb">
                      128GB
                    </a>
                    <a className="variant" href="/fr/apple-iphone-15-256gb">
                      256GB
                    </a>
                  </div>
                </div>
                <div className="trophyLabelsAndPrices">
                  <div className="trophyWrapper ecommerce" />
                  <div className="priceTagContainer">
                    <div className="pricetags vs rivalIdx0">
                      <div className="wrapper">
                        <a
                          className="cell"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwQ0hYNlNEOTY%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9naSZ0aD0xJnBzYz0x"
                        >
                          <span className="currency">
                            <em>€</em>
                          </span>
                          <span className="natural">
                            <em>1 189</em>
                          </span>
                        </a>
                        <a
                          className="cell merchantLogo"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                          href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwQ0hYNlNEOTY%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9naSZ0aD0xJnBzYz0x"
                        >
                          <img
                            alt="Apple iPhone 15 amazon_fr pricetag"
                            src="/img/amazon-logo.png"
                          />
                        </a>
                        <button className="cell arrow visible">
                          <svg
                            stroke="currentColor"
                            fill="currentColor"
                            strokeWidth={0}
                            viewBox="0 0 24 24"
                            height="1em"
                            width="1em"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path fill="none" d="M0 0h24v24H0V0z" />
                            <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                          </svg>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modernImage">
                  <picture>
                    <source
                      media="(min-width: 641px)"
                      type="image/webp"
                      srcSet="https://images.versus.io/objects/apple-iphone-15.front.master.1694550535352.webp, https://images.versus.io/objects/apple-iphone-15.front.master2x.1694550535352.webp 2x"
                    />
                    <source
                      media="(min-width: 641px)"
                      type="image/jpeg"
                      srcSet="https://images.versus.io/objects/apple-iphone-15.front.master.1694550535352.jpg, https://images.versus.io/objects/apple-iphone-15.front.master2x.1694550535352.jpg 2x"
                    />
                    <source
                      type="image/webp"
                      srcSet="https://images.versus.io/objects/apple-iphone-15.front.master.1694550535352.webp"
                    />
                    <source
                      type="image/jpeg"
                      srcSet="https://images.versus.io/objects/apple-iphone-15.front.master.1694550535352.jpg"
                    />
                    <img
                      src="https://images.versus.io/objects/apple-iphone-15.front.master.1694550535352.jpg"
                      alt="Apple iPhone 15"
                    />
                  </picture>
                  <div className="gradient" />
                  <div className="hoverOverlay">
                    <button
                      className="closeButton"
                      aria-label="Remove Apple iPhone 15 from comparison"
                    >
                      <svg
                        stroke="currentColor"
                        fill="currentColor"
                        strokeWidth={0}
                        viewBox="0 0 352 512"
                        height={14}
                        width={14}
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M242.72 256l100.07-100.07c12.28-12.28 12.28-32.19 0-44.48l-22.24-22.24c-12.28-12.28-32.19-12.28-44.48 0L176 189.28 75.93 89.21c-12.28-12.28-32.19-12.28-44.48 0L9.21 111.45c-12.28 12.28-12.28 32.19 0 44.48L109.28 256 9.21 356.07c-12.28 12.28-12.28 32.19 0 44.48l22.24 22.24c12.28 12.28 32.2 12.28 44.48 0L176 322.72l100.07 100.07c12.28 12.28 32.2 12.28 44.48 0l22.24-22.24c12.28-12.28 12.28-32.19 0-44.48L242.72 256z" />
                      </svg>
                    </button>
                    <div data-cy="expandImage">
                      <span
                        className="VsIcon__icon___3m84T"
                        style={{ width: 40, height: 40 }}
                      >
                        {/* Generated by IcoMoon.io */}
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width={32}
                          height={32}
                          viewBox="0 0 32 32"
                        >
                          <title>_expand</title>
                          <path d="M3 3.767l10.902 10.85 0.705-0.709-10.895-10.844h7.062v-1h-8.774v8.73h1zM29 21.205v7.027l-10.902-10.85-0.705 0.709 10.896 10.845h-7.063v1h8.774v-8.731zM18.092 14.606l10.844-10.895v7.062h1v-8.773h-8.73v1h7.026l-10.849 10.901zM13.908 17.393l-10.844 10.895v-7.062h-1v8.774h8.73v-1h-7.027l10.85-10.902z" />
                        </svg>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="placeholder">
            <div className="vsSeparator">
              <span>vs</span>
            </div>
            <div className="nameAreaPlaceholder">
              <div className="scorePlaceholder" />
              <div className="namePlaceholder" />
            </div>
            <div className="pricePlaceholder" />
            <div className="imagePlaceholder" />
          </div>
        </div>
      </div>

      <div className="comparisonTitle">
        <h1>Honor X9c 5G: test, caractéristiques et prix</h1>
      </div>

      <section id="overview" className="summaryContainer">
        <div className="summary">
          <div id="lead_top_t" className="summaryLeaderAd vs-ads" />
          <div className="summaryHead">
            <button
              aria-label="move summary head left"
              className="chevron left"
              disabled=""
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 320 512"
                height={16}
                width={16}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z" />
              </svg>
            </button>
            <div className="summaryNameContainer">
              <div
                style={{ transform: "translateX(-0%%)" }}
                className="summaryName selected"
              >
                Honor X9c 5G
              </div>
            </div>
            <button
              aria-label="move summary head right"
              className="chevron right"
              disabled=""
            >
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 320 512"
                height={16}
                width={16}
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z" />
              </svg>
            </button>
            <div className="embed-icon">
              <svg
                stroke="currentColor"
                fill="currentColor"
                strokeWidth={0}
                viewBox="0 0 24 24"
                height="1em"
                width="1em"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path fill="none" d="M0 0h24v24H0V0z" />
                <path d="M9.4 16.6L4.8 12l4.6-4.6L8 6l-6 6 6 6 1.4-1.4zm5.2 0l4.6-4.6-4.6-4.6L16 6l6 6-6 6-1.4-1.4z" />
              </svg>
            </div>
          </div>
          <div className="summaryButtonsContainer Summary__summaryButtonsContainer___1CfPf">
            <div className="summaryButtons">
              <div className="summaryButton">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 30, height: 30 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_smartphone</title>
                    <path d="M22 2h-12c-1.103 0-2 0.897-2 2v24c0 1.103 0.897 2 2 2h12c1.103 0 2-0.897 2-2v-24c0-1.103-0.897-2-2-2zM9 6.010h14v19.017h-14v-19.017zM9 4c0-0.552 0.449-1 1-1h12c0.551 0 1 0.448 1 1v1.010h-14v-1.010zM23 28c0 0.552-0.449 1-1 1h-12c-0.551 0-1-0.448-1-1v-1.973h14v1.973zM14.5 27.995h3c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-3c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5z" />
                  </svg>
                </span>
              </div>
              <div className="summaryButton">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 30, height: 30 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_image</title>
                    <path d="M0 4v24h32v-24h-32zM31 5v14.895l-6.761-5.081-3.57 1.903-4.981-2.96-14.688 9.092v-17.849h30zM1 27v-2.976l14.699-9.098 4.944 2.938 3.504-1.87 6.853 5.151v5.855h-30zM21.823 13.465c1.737 0 3.15-1.416 3.15-3.157s-1.413-3.156-3.15-3.156-3.15 1.416-3.15 3.156c0 1.741 1.413 3.157 3.15 3.157zM21.823 8.151c1.186 0 2.15 0.968 2.15 2.156s-0.965 2.157-2.15 2.157-2.15-0.968-2.15-2.157c0-1.188 0.965-2.156 2.15-2.156z" />
                  </svg>
                </span>
              </div>
              <div className="summaryButton">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 30, height: 30 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_chip</title>
                    <path d="M7.831 24.22h16.3v-16.3h-16.3v16.3zM8.831 8.92h14.3v14.3h-14.3v-14.3zM30 8.827v-1h-2.232v-1.545c0-1.103-0.897-2-2-2h-1.525v-2.212h-1v2.212h-1.213v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.213v-2.212h-1v2.212h-1.564c-1.103 0-2 0.897-2 2v1.545h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.213h-2.193v1h2.193v1.212h-2.193v1h2.193v1.545c0 1.103 0.897 2 2 2h1.564v2.212h1v-2.212h1.213v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.525c1.103 0 2-0.897 2-2v-1.545h2.233v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.213h2.232zM26.768 25.858c0 0.551-0.449 1-1 1h-19.575c-0.551 0-1-0.449-1-1v-19.576c0-0.551 0.449-1 1-1h19.575c0.551 0 1 0.449 1 1v19.576z" />
                  </svg>
                </span>
              </div>
              <div className="summaryButton">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 30, height: 30 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_shutter</title>
                    <path d="M10.028 12.424l-5.566 9.64c-0.961-1.842-1.463-3.887-1.463-5.997 0-1.243 0.174-2.466 0.518-3.643h6.511zM11.758 11.424h-8.969c-0.511 1.453-0.789 3.016-0.789 4.644 0 2.957 0.917 5.7 2.482 7.959l7.276-12.603zM16.578 3.081c3.348 0.148 6.501 1.581 8.818 4.003l-3.254 5.636-5.564-9.639zM16 2.068c-0.382 0-0.759 0.017-1.134 0.045l7.276 12.604 4.483-7.765c-2.567-2.99-6.375-4.884-10.625-4.884v0zM12.916 3.437l3.253 5.635h-11.129c1.798-2.809 4.626-4.844 7.876-5.635zM13.417 2.307c-4.462 0.832-8.18 3.78-10.071 7.765h14.554l-4.483-7.765zM27.539 10.071c0.961 1.842 1.463 3.887 1.463 5.997 0 1.244-0.174 2.466-0.518 3.644h-6.511l5.566-9.641zM27.518 8.109l-7.276 12.603h8.969c0.511-1.453 0.789-3.016 0.789-4.644 0-2.957-0.917-5.699-2.482-7.959v0zM26.96 23.063c-1.798 2.81-4.626 4.845-7.876 5.636l-3.254-5.636h11.13zM28.654 22.063h-14.554l4.483 7.766c4.463-0.832 8.18-3.781 10.071-7.766v0zM9.858 19.417l5.564 9.638c-3.347-0.149-6.501-1.582-8.817-4.003l3.253-5.635zM9.858 17.419l-4.483 7.764c2.567 2.99 6.375 4.884 10.625 4.884 0.382 0 0.759-0.016 1.134-0.045l-7.276-12.603z" />
                  </svg>
                </span>
              </div>
              <div className="summaryButton">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 30, height: 30 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_phone_pc</title>
                    <path d="M30.136 9.976h-9.109c-1.103 0-2 0.897-2 2v18c0 1.103 0.897 2 2 2h9.109c1.103 0 2-0.897 2-2v-18c0-1.103-0.897-2-2-2zM20.027 13.318h11.109v14.568h-11.109v-14.568zM21.027 10.976h9.109c0.551 0 1 0.449 1 1v0.341h-11.109v-0.341c0-0.551 0.449-1 1-1zM30.136 30.976h-9.109c-0.551 0-1-0.449-1-1v-1.090h11.109v1.090c0 0.552-0.448 1-1 1zM26.792 29.417h-2.422c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h2.422c0.276 0 0.5-0.224 0.5-0.5s-0.223-0.5-0.5-0.5zM0.987 5.975h26v3.034h1v-3.534c0-0.276-0.224-0.5-0.5-0.5h-27c-0.276 0-0.5 0.224-0.5 0.5v17c0 0.276 0.224 0.5 0.5 0.5h13v2.069l-3.5 0.913 0.253 0.968 3.747-0.978 3.747 0.978 0.253-0.968-3.5-0.913v-2.069h3.513v-1h-17.013v-0.985h17.013v-1h-17.013v-14.015z" />
                  </svg>
                </span>
              </div>
              <div className="summaryButton">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 30, height: 30 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_battery</title>
                    <path d="M25.031 10.99h-21.042v9.996h21.042v-9.996zM4.989 19.987v-3.342l4.637-4.654h3.382l-7.967 7.996h-0.052zM14.421 11.99h3.383l-7.968 7.996h-3.382l7.967-7.996zM19.217 11.99h3.383l-7.968 7.996h-3.382l7.967-7.996zM24.012 11.99h0.019v3.375l-4.604 4.621h-3.382l7.967-7.996zM8.214 11.99l-3.225 3.236v-3.236h3.225zM20.839 19.987l3.192-3.203v3.203h-3.192zM30 11.985h-3.009v-3.013h-24.991v14.052h24.991v-3.040h2.963l0.046-7.999zM25.991 22.024h-22.991v-12.052h22.991v12.052zM28.96 18.985h-1.969v-6h2.003l-0.034 6z" />
                  </svg>
                </span>
              </div>
              <div className="summaryButton">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 30, height: 30 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_musical_note</title>
                    <path d="M12 2v22.625c-0.912-0.981-2.359-1.625-4-1.625-2.757 0-5 1.794-5 4s2.243 4 5 4 5-1.794 5-4c0-0.105-0.029-0.204-0.039-0.307 0.025-0.060 0.039-0.125 0.039-0.193v-19.5h16v15.625c-0.912-0.981-2.359-1.625-4-1.625-2.757 0-5 1.794-5 4s2.243 4 5 4 5-1.794 5-4c0-0.105-0.029-0.204-0.039-0.307 0.025-0.060 0.039-0.125 0.039-0.193v-22.5h-18zM8 30c-2.206 0-4-1.346-4-3s1.794-3 4-3 4 1.346 4 3-1.794 3-4 3zM25 28c-2.206 0-4-1.346-4-3s1.794-3 4-3 4 1.346 4 3-1.794 3-4 3zM13 6v-3h16v3h-16z" />
                  </svg>
                </span>
              </div>
              <div className="summaryButton">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 30, height: 30 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_plus</title>
                    <path d="M25.192 6.808c-2.461-2.461-5.731-3.819-9.208-3.823-0.005 0-0.011 0-0.017 0-3.47 0-6.731 1.35-9.183 3.801-5.068 5.068-5.059 13.326 0.022 18.407 2.544 2.544 5.885 3.817 9.224 3.817 3.328 0 6.653-1.265 9.183-3.795 5.070-5.069 5.060-13.327-0.021-18.407zM24.507 24.508c-4.679 4.679-12.302 4.669-16.993-0.022s-4.701-12.314-0.022-16.993c2.263-2.263 5.272-3.508 8.476-3.508 0.005 0 0.010 0 0.016 0 3.21 0.004 6.229 1.258 8.501 3.53 4.691 4.691 4.701 12.313 0.022 16.993zM16.5 8.021h-1v7.479h-7.479v1h7.479v7.479h1v-7.479h7.479v-1h-7.479z" />
                  </svg>
                </span>
              </div>
              <div className="summaryButton">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 30, height: 30 }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_versus</title>
                    <path d="M16 29c-7.186 0-13.031-5.832-13.031-13s5.845-13 13.031-13 13.031 5.832 13.031 13-5.845 13-13.031 13zM16 4c-6.634 0-12.031 5.383-12.031 12s5.397 12 12.031 12 12.031-5.383 12.031-12-5.397-12-12.031-12zM23.329 16.541c-0.155-0.272-0.36-0.499-0.615-0.682s-0.546-0.33-0.873-0.441c-0.327-0.111-0.663-0.205-1.006-0.283-0.333-0.078-0.66-0.15-0.981-0.216-0.322-0.067-0.607-0.141-0.857-0.225s-0.452-0.191-0.607-0.324-0.233-0.305-0.233-0.516c0-0.177 0.044-0.319 0.133-0.424s0.197-0.186 0.324-0.241 0.269-0.091 0.424-0.108c0.155-0.017 0.299-0.025 0.432-0.025 0.421 0 0.787 0.080 1.098 0.241 0.31 0.161 0.482 0.469 0.516 0.923h2.245c-0.044-0.532-0.18-0.973-0.407-1.322s-0.513-0.629-0.857-0.84-0.735-0.36-1.173-0.449c-0.438-0.089-0.89-0.133-1.356-0.133s-0.92 0.042-1.364 0.125c-0.444 0.083-0.843 0.227-1.198 0.432-0.082 0.047-0.147 0.112-0.221 0.168l0.167-0.492h-2.345l-1.813 5.871h-0.033l-1.813-5.871h-2.478l2.944 8.599h2.628l1.862-5.501c0.038 0.139 0.086 0.271 0.155 0.387 0.155 0.261 0.36 0.477 0.615 0.649s0.546 0.31 0.873 0.416c0.327 0.105 0.663 0.197 1.006 0.274 0.843 0.177 1.5 0.355 1.971 0.532s0.707 0.444 0.707 0.798c0 0.211-0.050 0.385-0.15 0.524s-0.225 0.25-0.374 0.333c-0.15 0.083-0.316 0.144-0.499 0.183s-0.358 0.058-0.524 0.058c-0.233 0-0.457-0.028-0.674-0.083s-0.407-0.141-0.574-0.258c-0.166-0.116-0.302-0.266-0.407-0.449s-0.158-0.402-0.158-0.657h-2.245c0.022 0.577 0.152 1.056 0.391 1.439s0.543 0.69 0.915 0.923c0.371 0.233 0.796 0.399 1.272 0.499s0.965 0.15 1.464 0.15c0.488 0 0.967-0.047 1.439-0.141 0.471-0.094 0.89-0.258 1.256-0.491s0.663-0.541 0.89-0.923c0.227-0.383 0.341-0.857 0.341-1.422 0-0.4-0.077-0.735-0.233-1.007z" />
                  </svg>
                </span>
              </div>
            </div>
          </div>
          <div className="summaryContent">
            <div className="radarChartContainer">
              <div className="RadarChart__radarChart___1XHL0">
                <svg
                  style={{ width: 0, height: 0, position: "absolute" }}
                  aria-hidden="true"
                  focusable="false"
                >
                  <linearGradient id="blue-gradient" x2={0} y2={1}>
                    <stop offset="0%" stopColor="#7600e0" />
                    <stop offset="100%" stopColor="#3c59fc" />
                  </linearGradient>
                  <linearGradient id="red-gradient" x2={0} y2={1}>
                    <stop offset="0%" stopColor="#ff164b" />
                    <stop offset="100%" stopColor="#ff5631" />
                  </linearGradient>
                  <linearGradient id="lime-gradient" x2={0} y2={1}>
                    <stop offset="0%" stopColor="#C5E626" />
                    <stop offset="100%" stopColor="#40E627" />
                  </linearGradient>
                  <linearGradient id="orange-gradient" x2={0} y2={1}>
                    <stop offset="0%" stopColor="#FFCD00" />
                    <stop offset="100%" stopColor="#FF5631" />
                  </linearGradient>
                  <linearGradient id="sage-gradient" x2={0} y2={1}>
                    <stop offset="0%" stopColor="#00FEB0" />
                    <stop offset="100%" stopColor="#39C3E0" />
                  </linearGradient>
                  <linearGradient id="magenta-gradient" x2={0} y2={1}>
                    <stop offset="0%" stopColor="#FF2DE0" />
                    <stop offset="100%" stopColor="#7600E0" />
                  </linearGradient>
                  <linearGradient id="lightblue-gradient" x2={0} y2={1}>
                    <stop offset="0%" stopColor="#39C3E0" />
                    <stop offset="100%" stopColor="#3C59FC" />
                  </linearGradient>
                </svg>
                <svg
                  version={1}
                  xmlns="http://www.w3.org/2000/svg"
                  width="356.25"
                  height="356.25"
                  viewBox="-10 0 376.25 356.25"
                >
                  <g transform="translate(178.1250,178.1250)">
                    <g>
                      <circle
                        cx={0}
                        cy={0}
                        r="137.01923076923077"
                        className="RadarChart__scale___2QRZA"
                        fill="none"
                      />
                      <circle
                        cx={0}
                        cy={0}
                        r="114.18269230769232"
                        className="RadarChart__scale___2QRZA"
                        fill="none"
                      />
                      <circle
                        cx={0}
                        cy={0}
                        r="91.34615384615384"
                        className="RadarChart__scale___2QRZA"
                        fill="none"
                      />
                      <circle
                        cx={0}
                        cy={0}
                        r="68.50961538461539"
                        className="RadarChart__scale___2QRZA"
                        fill="none"
                      />
                      <circle
                        cx={0}
                        cy={0}
                        r="45.67307692307692"
                        className="RadarChart__scale___2QRZA"
                        fill="none"
                      />
                      <circle
                        cx={0}
                        cy={0}
                        r="22.83653846153846"
                        className="RadarChart__scale___2QRZA"
                        fill="none"
                      />
                    </g>
                    <g>
                      <polyline
                        points="0.0000,0.0000 0.0000,-137.0192"
                        className="RadarChart__axis___bhp3g"
                      />
                      <polyline
                        points="0.0000,0.0000 107.1259,-85.4301"
                        className="RadarChart__axis___bhp3g"
                      />
                      <polyline
                        points="0.0000,0.0000 133.5839,30.4896"
                        className="RadarChart__axis___bhp3g"
                      />
                      <polyline
                        points="0.0000,0.0000 59.4504,123.4501"
                        className="RadarChart__axis___bhp3g"
                      />
                      <polyline
                        points="0.0000,0.0000 -59.4504,123.4501"
                        className="RadarChart__axis___bhp3g"
                      />
                      <polyline
                        points="0.0000,0.0000 -133.5839,30.4896"
                        className="RadarChart__axis___bhp3g"
                      />
                      <polyline
                        points="0.0000,0.0000 -107.1259,-85.4301"
                        className="RadarChart__axis___bhp3g"
                      />
                    </g>
                    <g>
                      <path
                        d="M0.0000,-88.6690L50.7053,-40.4361L83.8909,19.1475L36.4462,75.6813L-56.8199,117.9877L-67.8404,15.4841L-81.2342,-64.7821z"
                        className="RadarChart__shape___2y0ze "
                        stroke="url(#blue-gradient) #3c59fc"
                        fill="url(#blue-gradient) #3c59fc"
                      />
                    </g>
                    <g>
                      <text
                        x={0.0}
                        y="-169.2188"
                        dy={6}
                        textAnchor="middle"
                        className="RadarChart__captions___1luhL"
                      >
                        Design
                      </text>
                      <text
                        x="132.3005"
                        y="-105.5062"
                        dy={6}
                        textAnchor="middle"
                        className="RadarChart__captions___1luhL"
                      >
                        Écran
                      </text>
                      <text
                        x="164.9761"
                        y="37.6547"
                        dy={6}
                        textAnchor="middle"
                        className="RadarChart__captions___1luhL"
                      >
                        Performance
                      </text>
                      <text
                        x="73.4213"
                        y="152.4608"
                        dy={6}
                        textAnchor="middle"
                        className="RadarChart__captions___1luhL"
                      >
                        Appareils photo
                      </text>
                      <text
                        x="-73.4213"
                        y="152.4608"
                        dy={6}
                        textAnchor="middle"
                        className="RadarChart__captions___1luhL"
                      >
                        Système d'opéra
                      </text>
                      <text
                        x="-164.9761"
                        y="37.6547"
                        dy={6}
                        textAnchor="middle"
                        className="RadarChart__captions___1luhL"
                      >
                        Batterie
                      </text>
                      <text
                        x="-132.3005"
                        y="-105.5062"
                        dy={6}
                        textAnchor="middle"
                        className="RadarChart__captions___1luhL"
                      >
                        Fonctionnalités
                      </text>
                    </g>
                    <g>
                      <circle
                        cx="5.429408880437537e-15"
                        cy="-88.66897597278992"
                        className="RadarChart__dot___3qU_p "
                      />
                      <circle
                        cx="50.70526281252013"
                        cy="-40.43609776927336"
                        className="RadarChart__dot___3qU_p "
                      />
                      <circle
                        cx="83.89088119317763"
                        cy="19.147546193182148"
                        className="RadarChart__dot___3qU_p "
                      />
                      <circle
                        cx="36.446203390084676"
                        cy="75.68132116333805"
                        className="RadarChart__dot___3qU_p "
                      />
                      <circle
                        cx="-56.81986678879527"
                        cy="117.98766913732328"
                        className="RadarChart__dot___3qU_p "
                      />
                      <circle
                        cx="-67.84043517634126"
                        cy="15.48413662878788"
                        className="RadarChart__dot___3qU_p "
                      />
                      <circle
                        cx="-81.23424668279702"
                        cy="-64.7821499954393"
                        className="RadarChart__dot___3qU_p "
                      />
                    </g>
                  </g>
                </svg>
                <div className="RadarChart__groupButtons___1k3zq">
                  <button
                    aria-label="Design"
                    title="Design"
                    className="RadarChart__groupButton___1jVh8"
                  >
                    <span
                      className="VsIcon__icon___3m84T"
                      style={{ width: 20, height: 20, fill: "black" }}
                    >
                      {/* Generated by IcoMoon.io */}
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                      >
                        <title>_smartphone</title>
                        <path d="M22 2h-12c-1.103 0-2 0.897-2 2v24c0 1.103 0.897 2 2 2h12c1.103 0 2-0.897 2-2v-24c0-1.103-0.897-2-2-2zM9 6.010h14v19.017h-14v-19.017zM9 4c0-0.552 0.449-1 1-1h12c0.551 0 1 0.448 1 1v1.010h-14v-1.010zM23 28c0 0.552-0.449 1-1 1h-12c-0.551 0-1-0.448-1-1v-1.973h14v1.973zM14.5 27.995h3c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-3c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5z" />
                      </svg>
                    </span>
                    <span className="RadarChart__tip___2izHT">Design</span>
                  </button>
                  <button
                    aria-label="Écran"
                    title="Écran"
                    className="RadarChart__groupButton___1jVh8"
                  >
                    <span
                      className="VsIcon__icon___3m84T"
                      style={{ width: 20, height: 20, fill: "black" }}
                    >
                      {/* Generated by IcoMoon.io */}
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                      >
                        <title>_image</title>
                        <path d="M0 4v24h32v-24h-32zM31 5v14.895l-6.761-5.081-3.57 1.903-4.981-2.96-14.688 9.092v-17.849h30zM1 27v-2.976l14.699-9.098 4.944 2.938 3.504-1.87 6.853 5.151v5.855h-30zM21.823 13.465c1.737 0 3.15-1.416 3.15-3.157s-1.413-3.156-3.15-3.156-3.15 1.416-3.15 3.156c0 1.741 1.413 3.157 3.15 3.157zM21.823 8.151c1.186 0 2.15 0.968 2.15 2.156s-0.965 2.157-2.15 2.157-2.15-0.968-2.15-2.157c0-1.188 0.965-2.156 2.15-2.156z" />
                      </svg>
                    </span>
                    <span className="RadarChart__tip___2izHT">Écran</span>
                  </button>
                  <button
                    aria-label="Performance"
                    title="Performance"
                    className="RadarChart__groupButton___1jVh8"
                  >
                    <span
                      className="VsIcon__icon___3m84T"
                      style={{ width: 20, height: 20, fill: "black" }}
                    >
                      {/* Generated by IcoMoon.io */}
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                      >
                        <title>_chip</title>
                        <path d="M7.831 24.22h16.3v-16.3h-16.3v16.3zM8.831 8.92h14.3v14.3h-14.3v-14.3zM30 8.827v-1h-2.232v-1.545c0-1.103-0.897-2-2-2h-1.525v-2.212h-1v2.212h-1.213v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.213v-2.212h-1v2.212h-1.564c-1.103 0-2 0.897-2 2v1.545h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.213h-2.193v1h2.193v1.212h-2.193v1h2.193v1.545c0 1.103 0.897 2 2 2h1.564v2.212h1v-2.212h1.213v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.525c1.103 0 2-0.897 2-2v-1.545h2.233v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.213h2.232zM26.768 25.858c0 0.551-0.449 1-1 1h-19.575c-0.551 0-1-0.449-1-1v-19.576c0-0.551 0.449-1 1-1h19.575c0.551 0 1 0.449 1 1v19.576z" />
                      </svg>
                    </span>
                    <span className="RadarChart__tip___2izHT">Performance</span>
                  </button>
                  <button
                    aria-label="Appareils photo"
                    title="Appareils photo"
                    className="RadarChart__groupButton___1jVh8"
                  >
                    <span
                      className="VsIcon__icon___3m84T"
                      style={{ width: 20, height: 20, fill: "black" }}
                    >
                      {/* Generated by IcoMoon.io */}
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                      >
                        <title>_shutter</title>
                        <path d="M10.028 12.424l-5.566 9.64c-0.961-1.842-1.463-3.887-1.463-5.997 0-1.243 0.174-2.466 0.518-3.643h6.511zM11.758 11.424h-8.969c-0.511 1.453-0.789 3.016-0.789 4.644 0 2.957 0.917 5.7 2.482 7.959l7.276-12.603zM16.578 3.081c3.348 0.148 6.501 1.581 8.818 4.003l-3.254 5.636-5.564-9.639zM16 2.068c-0.382 0-0.759 0.017-1.134 0.045l7.276 12.604 4.483-7.765c-2.567-2.99-6.375-4.884-10.625-4.884v0zM12.916 3.437l3.253 5.635h-11.129c1.798-2.809 4.626-4.844 7.876-5.635zM13.417 2.307c-4.462 0.832-8.18 3.78-10.071 7.765h14.554l-4.483-7.765zM27.539 10.071c0.961 1.842 1.463 3.887 1.463 5.997 0 1.244-0.174 2.466-0.518 3.644h-6.511l5.566-9.641zM27.518 8.109l-7.276 12.603h8.969c0.511-1.453 0.789-3.016 0.789-4.644 0-2.957-0.917-5.699-2.482-7.959v0zM26.96 23.063c-1.798 2.81-4.626 4.845-7.876 5.636l-3.254-5.636h11.13zM28.654 22.063h-14.554l4.483 7.766c4.463-0.832 8.18-3.781 10.071-7.766v0zM9.858 19.417l5.564 9.638c-3.347-0.149-6.501-1.582-8.817-4.003l3.253-5.635zM9.858 17.419l-4.483 7.764c2.567 2.99 6.375 4.884 10.625 4.884 0.382 0 0.759-0.016 1.134-0.045l-7.276-12.603z" />
                      </svg>
                    </span>
                    <span className="RadarChart__tip___2izHT">
                      Appareils photo
                    </span>
                  </button>
                  <button
                    aria-label="Système d'opération"
                    title="Système d'opération"
                    className="RadarChart__groupButton___1jVh8"
                  >
                    <span
                      className="VsIcon__icon___3m84T"
                      style={{ width: 20, height: 20, fill: "black" }}
                    >
                      {/* Generated by IcoMoon.io */}
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                      >
                        <title>_phone_pc</title>
                        <path d="M30.136 9.976h-9.109c-1.103 0-2 0.897-2 2v18c0 1.103 0.897 2 2 2h9.109c1.103 0 2-0.897 2-2v-18c0-1.103-0.897-2-2-2zM20.027 13.318h11.109v14.568h-11.109v-14.568zM21.027 10.976h9.109c0.551 0 1 0.449 1 1v0.341h-11.109v-0.341c0-0.551 0.449-1 1-1zM30.136 30.976h-9.109c-0.551 0-1-0.449-1-1v-1.090h11.109v1.090c0 0.552-0.448 1-1 1zM26.792 29.417h-2.422c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h2.422c0.276 0 0.5-0.224 0.5-0.5s-0.223-0.5-0.5-0.5zM0.987 5.975h26v3.034h1v-3.534c0-0.276-0.224-0.5-0.5-0.5h-27c-0.276 0-0.5 0.224-0.5 0.5v17c0 0.276 0.224 0.5 0.5 0.5h13v2.069l-3.5 0.913 0.253 0.968 3.747-0.978 3.747 0.978 0.253-0.968-3.5-0.913v-2.069h3.513v-1h-17.013v-0.985h17.013v-1h-17.013v-14.015z" />
                      </svg>
                    </span>
                    <span className="RadarChart__tip___2izHT">
                      Système d'opération
                    </span>
                  </button>
                  <button
                    aria-label="Batterie"
                    title="Batterie"
                    className="RadarChart__groupButton___1jVh8"
                  >
                    <span
                      className="VsIcon__icon___3m84T"
                      style={{ width: 20, height: 20, fill: "black" }}
                    >
                      {/* Generated by IcoMoon.io */}
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                      >
                        <title>_battery</title>
                        <path d="M25.031 10.99h-21.042v9.996h21.042v-9.996zM4.989 19.987v-3.342l4.637-4.654h3.382l-7.967 7.996h-0.052zM14.421 11.99h3.383l-7.968 7.996h-3.382l7.967-7.996zM19.217 11.99h3.383l-7.968 7.996h-3.382l7.967-7.996zM24.012 11.99h0.019v3.375l-4.604 4.621h-3.382l7.967-7.996zM8.214 11.99l-3.225 3.236v-3.236h3.225zM20.839 19.987l3.192-3.203v3.203h-3.192zM30 11.985h-3.009v-3.013h-24.991v14.052h24.991v-3.040h2.963l0.046-7.999zM25.991 22.024h-22.991v-12.052h22.991v12.052zM28.96 18.985h-1.969v-6h2.003l-0.034 6z" />
                      </svg>
                    </span>
                    <span className="RadarChart__tip___2izHT">Batterie</span>
                  </button>
                  <button
                    aria-label="Fonctionnalités"
                    title="Fonctionnalités"
                    className="RadarChart__groupButton___1jVh8"
                  >
                    <span
                      className="VsIcon__icon___3m84T"
                      style={{ width: 20, height: 20, fill: "black" }}
                    >
                      {/* Generated by IcoMoon.io */}
                      <svg
                        version="1.1"
                        xmlns="http://www.w3.org/2000/svg"
                        width={32}
                        height={32}
                        viewBox="0 0 32 32"
                      >
                        <title>_plus</title>
                        <path d="M25.192 6.808c-2.461-2.461-5.731-3.819-9.208-3.823-0.005 0-0.011 0-0.017 0-3.47 0-6.731 1.35-9.183 3.801-5.068 5.068-5.059 13.326 0.022 18.407 2.544 2.544 5.885 3.817 9.224 3.817 3.328 0 6.653-1.265 9.183-3.795 5.070-5.069 5.060-13.327-0.021-18.407zM24.507 24.508c-4.679 4.679-12.302 4.669-16.993-0.022s-4.701-12.314-0.022-16.993c2.263-2.263 5.272-3.508 8.476-3.508 0.005 0 0.010 0 0.016 0 3.21 0.004 6.229 1.258 8.501 3.53 4.691 4.691 4.701 12.313 0.022 16.993zM16.5 8.021h-1v7.479h-7.479v1h7.479v7.479h1v-7.479h7.479v-1h-7.479z" />
                      </svg>
                    </span>
                    <span className="RadarChart__tip___2izHT">
                      Fonctionnalités
                    </span>
                  </button>
                </div>
                <div className="RadarChart__pointsContainer___34AsW">
                  <div className="RadarChart__rivalValue___3w6RE">
                    <div className="RadarChart__value___Ootfo">52</div>
                    <div className="RadarChart__pointsLabel___2bhme">
                      points
                    </div>
                    <div className="RadarChart__tip___2izHT">Honor X9c 5G</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tldrContainer">
              <div style={{ transform: "translateX(0%" }} className="tldr">
                <h2>Pourquoi Honor X9c 5G est au-dessus de la moyenne?</h2>
                <ul>
                  <li>
                    <span>Capacité de la batterie</span>
                    <span className="help">
                      <div className="helpTouchArea" />
                      <span>?</span>
                    </span>
                    <br />
                    <em className="value">6600 mAh</em>
                    <span className="vs">vs</span>
                    <em className="otherValue">4044.55 mAh</em>
                  </li>
                  <li>
                    <span>Densité de pixels</span>
                    <span className="help">
                      <div className="helpTouchArea" />
                      <span>?</span>
                    </span>
                    <br />
                    <em className="value">437 ppi</em>
                    <span className="vs">vs</span>
                    <em className="otherValue">360.3 ppi</em>
                  </li>
                  <li>
                    <span>Mémoire vive (RAM)</span>
                    <span className="help">
                      <div className="helpTouchArea" />
                      <span>?</span>
                    </span>
                    <br />
                    <em className="value">12GB</em>
                    <span className="vs">vs</span>
                    <em className="otherValue">5.76GB</em>
                  </li>
                  <li>
                    <span>Résolution</span>
                    <span className="help">
                      <div className="helpTouchArea" />
                      <span>?</span>
                    </span>
                    <br />
                    <em className="value">1224 x 2700 px</em>
                    <span className="vs">vs</span>
                    <em className="otherValue">2.06 MP</em>
                  </li>
                  <li>
                    <span>Mégapixels (appareil photo principal)</span>
                    <span className="help">
                      <div className="helpTouchArea" />
                      <span>?</span>
                    </span>
                    <br />
                    <em className="value">108 MP &amp; 5 MP</em>
                    <span className="vs">vs</span>
                    <em className="otherValue">44.18 MP</em>
                  </li>
                  <li>
                    <span>Vitesse du processeur</span>
                    <span className="help">
                      <div className="helpTouchArea" />
                      <span>?</span>
                    </span>
                    <br />
                    <em className="value">4 x 2.2 GHz &amp; 4 x 1.8 GHz</em>
                    <span className="vs">vs</span>
                    <em className="otherValue">13.78 GHz</em>
                  </li>
                  <li>
                    <span>Taille d'écran</span>
                    <span className="help">
                      <div className="helpTouchArea" />
                      <span>?</span>
                    </span>
                    <br />
                    <em className="value">6.78"</em>
                    <span className="vs">vs</span>
                    <em className="otherValue">6.1"</em>
                  </li>
                  <li>
                    <span>Mégapixels (caméra frontale)</span>
                    <span className="help">
                      <div className="helpTouchArea" />
                      <span>?</span>
                    </span>
                    <br />
                    <em className="value">16MP</em>
                    <span className="vs">vs</span>
                    <em className="otherValue">12.56MP</em>
                  </li>
                </ul>
                <div className="scrollDownButtonContainer">
                  <button>
                    <span className="scrollDownIcon">
                      <span className="VsIcon__icon___3m84T">
                        {/* Generated by IcoMoon.io */}
                        <svg
                          version="1.1"
                          xmlns="http://www.w3.org/2000/svg"
                          width={32}
                          height={32}
                          viewBox="0 0 32 32"
                        >
                          <title>_arrow_circle_down</title>
                          <path d="M25.192 6.808c-2.461-2.461-5.73-3.819-9.207-3.823-0.006 0-0.012 0-0.017 0-3.471 0-6.732 1.35-9.184 3.801-5.068 5.068-5.058 13.326 0.022 18.407 2.544 2.544 5.886 3.817 9.224 3.817 3.328 0 6.653-1.265 9.184-3.795 5.069-5.069 5.058-13.327-0.022-18.407zM24.508 24.508c-4.679 4.678-12.301 4.669-16.993-0.022-4.69-4.691-4.7-12.314-0.022-16.993 2.263-2.263 5.272-3.508 8.477-3.508 0.005 0 0.010 0 0.015 0 3.21 0.004 6.229 1.258 8.502 3.53 4.689 4.69 4.699 12.313 0.021 16.993zM16.5 21.979v-12.798h-1v12.798l-5.089-5.090-0.707 0.707 6.296 6.297 6.296-6.297-0.707-0.707z" />
                        </svg>
                      </span>
                    </span>
                    <span>
                      Faire défiler vers le bas pour plus d'informations
                    </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div id="lead_bottom_t" className="summaryLeaderAd vs-ads" />
        </div>
      </section>

      <div className="trendingContainer">
        <div
          className="CarouList__carouList___2WspW CarouList__isLandingPage___rPe4J"
          data-cy="carouList"
        >
          <div
            className="CarouList__listContainer___3Ipq4"
            data-cy="carouListContainer"
          >
            <h3>Quels sont les comparatifs les plus populaires?</h3>
            <div className="slick-slider slick-initialized" dir="ltr">
              <button
                className="CarouList__nav___3M3qV CarouList__left___1y57d"
                aria-label="Move carousel to left"
                data-cy="carouselNavBtn"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" />
                </svg>
              </button>
              <div className="slick-list">
                <div
                  className="slick-track"
                  style={{
                    width: 5428,
                    opacity: 1,
                    transform: "translate3d(-708px, 0px, 0px)",
                  }}
                >
                  <div
                    data-index={-3}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-a05-vs-samsung-galaxy-a15"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05.front.medium.1695632998583.webp, https://images.versus.io/objects/samsung-galaxy-a05.front.medium2x.1695632998583.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05.front.medium.1695632998583.jpg, https://images.versus.io/objects/samsung-galaxy-a05.front.medium2x.1695632998583.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a05.front.medium.1695632998583.jpg"
                                alt="Samsung Galaxy A05"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.webp, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg"
                                alt="Samsung Galaxy A15"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy A05</p>
                          <p>vs</p>
                          <p>Samsung Galaxy A15</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={-2}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-s23-vs-samsung-galaxy-s24"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23.front.medium.1675277832905.webp, https://images.versus.io/objects/samsung-galaxy-s23.front.medium2x.1675277832905.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23.front.medium.1675277832905.jpg, https://images.versus.io/objects/samsung-galaxy-s23.front.medium2x.1675277832905.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s23.front.medium.1675277832905.jpg"
                                alt="Samsung Galaxy S23"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.webp, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg"
                                alt="Samsung Galaxy S24"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy S23</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={-1}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/apple-iphone-15-vs-samsung-galaxy-s24"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/apple-iphone-15.front.medium.1694550535352.webp, https://images.versus.io/objects/apple-iphone-15.front.medium2x.1694550535352.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/apple-iphone-15.front.medium.1694550535352.jpg, https://images.versus.io/objects/apple-iphone-15.front.medium2x.1694550535352.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/apple-iphone-15.front.medium.1694550535352.jpg"
                                alt="Apple iPhone 15"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.webp, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg"
                                alt="Samsung Galaxy S24"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Apple iPhone 15</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={0}
                    className="slick-slide slick-active slick-current"
                    tabIndex={-1}
                    aria-hidden="false"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/xiaomi-redmi-note-13-vs-xiaomi-redmi-note-13-pro"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium.1695387349873.webp, https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium2x.1695387349873.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium.1695387349873.jpg, https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium2x.1695387349873.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium.1695387349873.jpg"
                                alt="Xiaomi Redmi Note 13"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium.1695391676696.webp, https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium2x.1695391676696.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium.1695391676696.jpg, https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium2x.1695391676696.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium.1695391676696.jpg"
                                alt="Xiaomi Redmi Note 13 Pro"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Xiaomi Redmi Note 13</p>
                          <p>vs</p>
                          <p>Xiaomi Redmi Note 13 Pro</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={1}
                    className="slick-slide slick-active"
                    tabIndex={-1}
                    aria-hidden="false"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/apple-iphone-15-pro-max-vs-samsung-galaxy-s24-ultra"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium.1694552264367.webp, https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium2x.1694552264367.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium.1694552264367.jpg, https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium2x.1694552264367.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium.1694552264367.jpg"
                                alt="Apple iPhone 15 Pro Max"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.webp, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg"
                                alt="Samsung Galaxy S24 Ultra"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Apple iPhone 15 Pro Max</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24 Ultra</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={2}
                    className="slick-slide slick-active"
                    tabIndex={-1}
                    aria-hidden="false"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-a15-vs-samsung-galaxy-a25-5g"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.webp, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg"
                                alt="Samsung Galaxy A15"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium.1702384393704.webp, https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium2x.1702384393704.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium.1702384393704.jpg, https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium2x.1702384393704.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium.1702384393704.jpg"
                                alt="Samsung Galaxy A25 5G"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy A15</p>
                          <p>vs</p>
                          <p>Samsung Galaxy A25 5G</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={3}
                    className="slick-slide"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-s23-ultra-vs-samsung-galaxy-s24-ultra"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium.1675277566339.webp, https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium2x.1675277566339.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium.1675277566339.jpg, https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium2x.1675277566339.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium.1675277566339.jpg"
                                alt="Samsung Galaxy S23 Ultra"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.webp, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg"
                                alt="Samsung Galaxy S24 Ultra"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy S23 Ultra</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24 Ultra</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={4}
                    className="slick-slide"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/apple-iphone-16-pro-max-vs-samsung-galaxy-s24-ultra"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium.1725967303304.webp, https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium2x.1725967303304.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium.1725967303304.jpg, https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium2x.1725967303304.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium.1725967303304.jpg"
                                alt="Apple iPhone 16 Pro Max"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.webp, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg"
                                alt="Samsung Galaxy S24 Ultra"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Apple iPhone 16 Pro Max</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24 Ultra</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={5}
                    className="slick-slide"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-s24-vs-samsung-galaxy-s24-fe"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.webp, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg"
                                alt="Samsung Galaxy S24"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium.1727966003467.webp, https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium2x.1727966003467.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium.1727966003467.jpg, https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium2x.1727966003467.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium.1727966003467.jpg"
                                alt="Samsung Galaxy S24 FE"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy S24</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24 FE</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={6}
                    className="slick-slide"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-a05s-vs-samsung-galaxy-a15"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05s.front.medium.1695633655024.webp, https://images.versus.io/objects/samsung-galaxy-a05s.front.medium2x.1695633655024.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05s.front.medium.1695633655024.jpg, https://images.versus.io/objects/samsung-galaxy-a05s.front.medium2x.1695633655024.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a05s.front.medium.1695633655024.jpg"
                                alt="Samsung Galaxy A05s"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.webp, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg"
                                alt="Samsung Galaxy A15"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy A05s</p>
                          <p>vs</p>
                          <p>Samsung Galaxy A15</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={7}
                    className="slick-slide"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-a05-vs-samsung-galaxy-a15"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05.front.medium.1695632998583.webp, https://images.versus.io/objects/samsung-galaxy-a05.front.medium2x.1695632998583.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05.front.medium.1695632998583.jpg, https://images.versus.io/objects/samsung-galaxy-a05.front.medium2x.1695632998583.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a05.front.medium.1695632998583.jpg"
                                alt="Samsung Galaxy A05"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.webp, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg"
                                alt="Samsung Galaxy A15"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy A05</p>
                          <p>vs</p>
                          <p>Samsung Galaxy A15</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={8}
                    className="slick-slide"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-s23-vs-samsung-galaxy-s24"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23.front.medium.1675277832905.webp, https://images.versus.io/objects/samsung-galaxy-s23.front.medium2x.1675277832905.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23.front.medium.1675277832905.jpg, https://images.versus.io/objects/samsung-galaxy-s23.front.medium2x.1675277832905.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s23.front.medium.1675277832905.jpg"
                                alt="Samsung Galaxy S23"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.webp, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg"
                                alt="Samsung Galaxy S24"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy S23</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={9}
                    className="slick-slide"
                    tabIndex={-1}
                    aria-hidden="true"
                    style={{ outline: "none", width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/apple-iphone-15-vs-samsung-galaxy-s24"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/apple-iphone-15.front.medium.1694550535352.webp, https://images.versus.io/objects/apple-iphone-15.front.medium2x.1694550535352.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/apple-iphone-15.front.medium.1694550535352.jpg, https://images.versus.io/objects/apple-iphone-15.front.medium2x.1694550535352.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/apple-iphone-15.front.medium.1694550535352.jpg"
                                alt="Apple iPhone 15"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.webp, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg"
                                alt="Samsung Galaxy S24"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Apple iPhone 15</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={10}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/xiaomi-redmi-note-13-vs-xiaomi-redmi-note-13-pro"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium.1695387349873.webp, https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium2x.1695387349873.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium.1695387349873.jpg, https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium2x.1695387349873.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/xiaomi-redmi-note-13.front.medium.1695387349873.jpg"
                                alt="Xiaomi Redmi Note 13"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium.1695391676696.webp, https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium2x.1695391676696.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium.1695391676696.jpg, https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium2x.1695391676696.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/xiaomi-redmi-note-13-pro.front.medium.1695391676696.jpg"
                                alt="Xiaomi Redmi Note 13 Pro"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Xiaomi Redmi Note 13</p>
                          <p>vs</p>
                          <p>Xiaomi Redmi Note 13 Pro</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={11}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/apple-iphone-15-pro-max-vs-samsung-galaxy-s24-ultra"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium.1694552264367.webp, https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium2x.1694552264367.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium.1694552264367.jpg, https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium2x.1694552264367.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/apple-iphone-15-pro-max.front.medium.1694552264367.jpg"
                                alt="Apple iPhone 15 Pro Max"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.webp, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg"
                                alt="Samsung Galaxy S24 Ultra"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Apple iPhone 15 Pro Max</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24 Ultra</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={12}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-a15-vs-samsung-galaxy-a25-5g"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.webp, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg"
                                alt="Samsung Galaxy A15"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium.1702384393704.webp, https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium2x.1702384393704.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium.1702384393704.jpg, https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium2x.1702384393704.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a25-5g.front.medium.1702384393704.jpg"
                                alt="Samsung Galaxy A25 5G"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy A15</p>
                          <p>vs</p>
                          <p>Samsung Galaxy A25 5G</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={13}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-s23-ultra-vs-samsung-galaxy-s24-ultra"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium.1675277566339.webp, https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium2x.1675277566339.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium.1675277566339.jpg, https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium2x.1675277566339.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s23-ultra.front.medium.1675277566339.jpg"
                                alt="Samsung Galaxy S23 Ultra"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.webp, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg"
                                alt="Samsung Galaxy S24 Ultra"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy S23 Ultra</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24 Ultra</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={14}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/apple-iphone-16-pro-max-vs-samsung-galaxy-s24-ultra"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium.1725967303304.webp, https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium2x.1725967303304.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium.1725967303304.jpg, https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium2x.1725967303304.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/apple-iphone-16-pro-max.front.medium.1725967303304.jpg"
                                alt="Apple iPhone 16 Pro Max"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.webp, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg, https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium2x.1720611066062.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24-ultra.front.medium.1720611066062.jpg"
                                alt="Samsung Galaxy S24 Ultra"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Apple iPhone 16 Pro Max</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24 Ultra</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={15}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-s24-vs-samsung-galaxy-s24-fe"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.webp, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg"
                                alt="Samsung Galaxy S24"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium.1727966003467.webp, https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium2x.1727966003467.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium.1727966003467.jpg, https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium2x.1727966003467.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24-fe.front.medium.1727966003467.jpg"
                                alt="Samsung Galaxy S24 FE"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy S24</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24 FE</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={16}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-a05s-vs-samsung-galaxy-a15"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05s.front.medium.1695633655024.webp, https://images.versus.io/objects/samsung-galaxy-a05s.front.medium2x.1695633655024.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05s.front.medium.1695633655024.jpg, https://images.versus.io/objects/samsung-galaxy-a05s.front.medium2x.1695633655024.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a05s.front.medium.1695633655024.jpg"
                                alt="Samsung Galaxy A05s"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.webp, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg"
                                alt="Samsung Galaxy A15"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy A05s</p>
                          <p>vs</p>
                          <p>Samsung Galaxy A15</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={17}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-a05-vs-samsung-galaxy-a15"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05.front.medium.1695632998583.webp, https://images.versus.io/objects/samsung-galaxy-a05.front.medium2x.1695632998583.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a05.front.medium.1695632998583.jpg, https://images.versus.io/objects/samsung-galaxy-a05.front.medium2x.1695632998583.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a05.front.medium.1695632998583.jpg"
                                alt="Samsung Galaxy A05"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.webp, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg, https://images.versus.io/objects/samsung-galaxy-a15.front.medium2x.1702383617589.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-a15.front.medium.1702383617589.jpg"
                                alt="Samsung Galaxy A15"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy A05</p>
                          <p>vs</p>
                          <p>Samsung Galaxy A15</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={18}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/samsung-galaxy-s23-vs-samsung-galaxy-s24"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23.front.medium.1675277832905.webp, https://images.versus.io/objects/samsung-galaxy-s23.front.medium2x.1675277832905.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s23.front.medium.1675277832905.jpg, https://images.versus.io/objects/samsung-galaxy-s23.front.medium2x.1675277832905.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s23.front.medium.1675277832905.jpg"
                                alt="Samsung Galaxy S23"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.webp, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg"
                                alt="Samsung Galaxy S24"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Samsung Galaxy S23</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div
                    data-index={19}
                    tabIndex={-1}
                    className="slick-slide slick-cloned"
                    aria-hidden="true"
                    style={{ width: 236 }}
                  >
                    <div>
                      <div
                        className="CarouList__carouselItem___yDyKl"
                        data-cy="carouListElement"
                        tabIndex={-1}
                        style={{ width: "100%", display: "inline-block" }}
                      >
                        <a
                          href="/fr/apple-iphone-15-vs-samsung-galaxy-s24"
                          className="Elem__trendLink___RNp5n"
                        >
                          <div className="Elem__imageWrapper___l5Zs- Elem__popular___LMS3- proportional Elem__phone___g7r91">
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/apple-iphone-15.front.medium.1694550535352.webp, https://images.versus.io/objects/apple-iphone-15.front.medium2x.1694550535352.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/apple-iphone-15.front.medium.1694550535352.jpg, https://images.versus.io/objects/apple-iphone-15.front.medium2x.1694550535352.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/apple-iphone-15.front.medium.1694550535352.jpg"
                                alt="Apple iPhone 15"
                                loading="lazy"
                              />
                            </picture>
                            <div className="Elem__separator___2ulxZ">
                              <span>vs</span>
                            </div>
                            <picture>
                              <source
                                type="image/webp"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.webp, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.webp 2x"
                              />
                              <source
                                type="image/jpeg"
                                srcSet="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg, https://images.versus.io/objects/samsung-galaxy-s24.front.medium2x.1705516436476.jpg 2x"
                              />
                              <img
                                data-cy="modernImage"
                                src="https://images.versus.io/objects/samsung-galaxy-s24.front.medium.1705516436476.jpg"
                                alt="Samsung Galaxy S24"
                                loading="lazy"
                              />
                            </picture>
                          </div>
                          <p>Apple iPhone 15</p>
                          <p>vs</p>
                          <p>Samsung Galaxy S24</p>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button
                className="CarouList__nav___3M3qV CarouList__right___2m23I"
                aria-label="Move carousel to right"
                data-cy="carouselNavBtn"
              >
                <svg
                  stroke="currentColor"
                  fill="currentColor"
                  strokeWidth={0}
                  viewBox="0 0 24 24"
                  height="1em"
                  width="1em"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path fill="none" d="M0 0h24v24H0V0z" />
                  <path d="M8.59 16.59L13.17 12 8.59 7.41 10 6l6 6-6 6-1.41-1.41z" />
                </svg>
              </button>
            </div>
          </div>
        </div>
      </div>

      <section>
        <div className="inlinePricesContainer InlinePrices__inlinePricesContainer___HM6E_">
          <h2>Comparaison de prix</h2>
          <div className="InlinePrices__inlinePrices___158Gj">
            <div className="InlinePrices__tableWrapper___2MY-W InlinePrices__one___dVGLe">
              <div className="PriceTable__priceTable___WdoPL PriceTable__uneven___2y-1a PriceTable__last___2hgdH">
                <div className="priceTitle PriceTable__single___2ooAb" />
                <div className="PriceTable__optionContainer___2EXMO">
                  <button
                    className="PriceTable__optionButton___1rbCc PriceTable__selected___2BZEN"
                    style={{ display: "block" }}
                  >
                    nouveau
                  </button>
                  <button
                    className="PriceTable__optionButton___1rbCc"
                    style={{ display: "none" }}
                  >
                    Seconde main
                  </button>
                  <button
                    className="PriceTable__optionButton___1rbCc"
                    style={{ display: "none" }}
                  >
                    Louer
                  </button>
                  <button
                    className="PriceTable__optionButton___1rbCc"
                    style={{ display: "none" }}
                  >
                    Accessoires
                  </button>
                </div>
                <div className="PriceTable__table___2ZDcF">
                  <div className="PriceTable__row___36X6C">
                    <div className="PriceTable__name___3sDxU PriceTable__single___2ooAb">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly90cmFjay5lZmZpbGlhdGlvbi5jb20vc2VydmxldC9lZmZpLnJlZGlyP2lkX2NvbXB0ZXVyPTIxNjYzNzQ2JnVybD1odHRwcyUzQSUyRiUyRmZyLnNob3BwaW5nLnJha3V0ZW4uY29tJTJGb2ZmZXIlMkZidXklMkY3NDA5MTA2NjMzJTJGaG9ub3ItNTAtMjU2LWdvLWFyZ2VudC5odG1s"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        Honor 50 256 Go Cristal de glace
                      </a>
                    </div>
                    <div className="PriceTable__logo___QM_CQ">
                      <div className="PriceTable__logoWrapper___NMm-C">
                        <a
                          href="/api/prices/out?link=aHR0cHM6Ly90cmFjay5lZmZpbGlhdGlvbi5jb20vc2VydmxldC9lZmZpLnJlZGlyP2lkX2NvbXB0ZXVyPTIxNjYzNzQ2JnVybD1odHRwcyUzQSUyRiUyRmZyLnNob3BwaW5nLnJha3V0ZW4uY29tJTJGb2ZmZXIlMkZidXklMkY3NDA5MTA2NjMzJTJGaG9ub3ItNTAtMjU2LWdvLWFyZ2VudC5odG1s"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <img
                            src="/img/rakuten-de.png"
                            alt="priceminister logo"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="PriceTable__price___1MLQ4">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly90cmFjay5lZmZpbGlhdGlvbi5jb20vc2VydmxldC9lZmZpLnJlZGlyP2lkX2NvbXB0ZXVyPTIxNjYzNzQ2JnVybD1odHRwcyUzQSUyRiUyRmZyLnNob3BwaW5nLnJha3V0ZW4uY29tJTJGb2ZmZXIlMkZidXklMkY3NDA5MTA2NjMzJTJGaG9ub3ItNTAtMjU2LWdvLWFyZ2VudC5odG1s"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        €226
                      </a>
                    </div>
                  </div>
                  <div className="PriceTable__row___36X6C">
                    <div className="PriceTable__name___3sDxU PriceTable__single___2ooAb">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwQ1BQRjg2Vkw%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        HONOR Magic6 Lite 5G Smartphone, 120Hz 6.78" AMOLED,
                        108MP Triple caméra arrière, 8+256GB, Android 13, Du...
                      </a>
                      <span className="PriceTable__fullName___2MSPs">
                        HONOR Magic6 Lite 5G Smartphone, 120Hz 6.78" AMOLED,
                        108MP Triple caméra arrière, 8+256GB, Android 13, Dual
                        Sim, Google Play, NFC, Orange
                      </span>
                    </div>
                    <div className="PriceTable__logo___QM_CQ">
                      <div className="PriceTable__logoWrapper___NMm-C">
                        <a
                          href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwQ1BQRjg2Vkw%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <img
                            src="/img/amazon-logo.png"
                            alt="amazon_fr logo"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="PriceTable__price___1MLQ4">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwQ1BQRjg2Vkw%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        €250
                      </a>
                    </div>
                  </div>
                  <div className="PriceTable__row___36X6C">
                    <div className="PriceTable__name___3sDxU PriceTable__single___2ooAb">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwRDFSR0ZSTFg%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        HONOR 200 Lite, Smartphone 5G, 8 Go + 256 Go, écran
                        AMOLED Anti-Chute 6,7 Pouces, Triple Appareil Photo a...
                      </a>
                      <span className="PriceTable__fullName___2MSPs">
                        HONOR 200 Lite, Smartphone 5G, 8 Go + 256 Go, écran
                        AMOLED Anti-Chute 6,7 Pouces, Triple Appareil Photo
                        arrière 108 Mpx, Appareil Photo Avant 50 Mpx, Double
                        SIM, Android 14, Noir
                      </span>
                    </div>
                    <div className="PriceTable__logo___QM_CQ">
                      <div className="PriceTable__logoWrapper___NMm-C">
                        <a
                          href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwRDFSR0ZSTFg%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <img
                            src="/img/amazon-logo.png"
                            alt="amazon_fr logo"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="PriceTable__price___1MLQ4">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwRDFSR0ZSTFg%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        €230
                      </a>
                    </div>
                  </div>
                  <div className="PriceTable__row___36X6C">
                    <div className="PriceTable__name___3sDxU PriceTable__single___2ooAb">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwREQzNzVNNEQ%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        HONOR 200 Smart 5G Téléphone Smartphone
                        5200mAh,4Go+256Go,Résistance aux Chutes 5
                        étoiles,Durabilité à l'...
                      </a>
                      <span className="PriceTable__fullName___2MSPs">
                        HONOR 200 Smart 5G Téléphone Smartphone
                        5200mAh,4Go+256Go,Résistance aux Chutes 5
                        étoiles,Durabilité à l'épreuve des éclaboussures,Capture
                        AI 50MP avec capteurs de Mouvement,Double SIM,Android
                        14,Vert
                      </span>
                    </div>
                    <div className="PriceTable__logo___QM_CQ">
                      <div className="PriceTable__logoWrapper___NMm-C">
                        <a
                          href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwREQzNzVNNEQ%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <img
                            src="/img/amazon-logo.png"
                            alt="amazon_fr logo"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="PriceTable__price___1MLQ4">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwREQzNzVNNEQ%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        €189
                      </a>
                    </div>
                  </div>
                  <div className="PriceTable__row___36X6C">
                    <div className="PriceTable__name___3sDxU PriceTable__single___2ooAb">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwRDJIVjVXU1I%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        HONOR 200 Lite 5G Smartphone avec Triple Caméra 108 Mpx,
                        8+256 Go, Écran 6,7" 90 Hz, 4500 mAh, Double SIM...
                      </a>
                      <span className="PriceTable__fullName___2MSPs">
                        HONOR 200 Lite 5G Smartphone avec Triple Caméra 108 Mpx,
                        8+256 Go, Écran 6,7" 90 Hz, 4500 mAh, Double SIM,
                        Android 14, NFC (Noir, 8+256G)
                      </span>
                    </div>
                    <div className="PriceTable__logo___QM_CQ">
                      <div className="PriceTable__logoWrapper___NMm-C">
                        <a
                          href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwRDJIVjVXU1I%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <img
                            src="/img/amazon-logo.png"
                            alt="amazon_fr logo"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="PriceTable__price___1MLQ4">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly93d3cuYW1hem9uLmZyL2RwL0IwRDJIVjVXU1I%2FdGFnPXZlcnN1czA0LTIxJmxpbmtDb2RlPW9zaSZ0aD0xJnBzYz0x"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        €230
                      </a>
                    </div>
                  </div>
                  <div className="PriceTable__row___36X6C">
                    <div className="PriceTable__name___3sDxU PriceTable__single___2ooAb">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly9zLmNsaWNrLmFsaWV4cHJlc3MuY29tL3MvTmRwd3p0YkFJZ0RNbXhHYlA4ZkZrczd5VUZmc0hqemxJbVNZbUE2OGg3eGxNUmd6dWVGc1Jnb2tROWY5aGNwVkt0dHh0UUUyVnpJb3FrWEpYcnBPcjZ1UjN6djdtUDRDRE85Q2Rkb0ZmNHQwUUU1RFhQeThQZ2poejRpcmZRU0VqRUdVbXk1eUYzQTVnSjloM05ZakxNZ3FJTXFhM3pjcHE1RTJCQzNXNUViVjQwRG05YnVReFdwWnp3MWxiWDhabTJrWnROSWE0S3lEaWVOanNIN0lVNll2OFN0RUxLVnZUOXI0ZUZBYVhQR2h3SHNnYURxZE5jbVkxMTZyM0dDMll2c09vcGVpaVpQaEpyVjBjd2dPUURTa1c5M3Y3Z1ZLRHFQa1h5WkYxb1NXYTZ3U0FPZXpuTUZWbFZaREN1YkV2b011QWt1MTBNWGFoWUw3WG5mN1RJSmNBZFMzT25WbDZxMWNNMjZGOTBoNjVIcWhDUDV6OWVhc3JoMm50ODFrbnhlVDRXWWN3MDB1Q2xTdlJud2ZDUGJ4dWY5SWtiMTBHd0tTNVVjSXhYeHhEMzRLczFWUlV1cDZZd0JlVDNwb05NR2lxWXpXZlRheTBDNFI3Qkl4dmYzeGlzVGZXaXFjalJIdTJJWUFmMHhObHNnRXRSYUpzalRRampReHJxOXhHV0R6NVhqbmNscTlVckJwQkRFUVc1aXpOTjNjTUk2b3laQ3BnSXYwbFlCUW92bmVSd2d3cXh6cTFnbmZqR0pncmtoVldUNFVGQXBZNHVGd3d5RVBkWTc5dTN2aVFlWDFXTk5UdnB1Q1RMdFFVc0lCc3RNb3FXOXl3dWtLRXZMYXFiZjNZYVVpeXowS2ZMTHA0cnJqejJLYlhNaUQxRlF5R21oV2M5Szd1Z0g1VUNRdk5UMkNNQk1hSUNLMzFKSWZRVHdpQ2RyYm9aZFhFTDNUM2U4NWw5SDBHTlYxc2dhUUdoaEtaZkkzcEM0dDJKRDFpR1NPR0RtbDRVZjlPY3ZTUFdrckxsNmF3bkx5cGgxZ203cFZkREluOUdzeVB0QjhDaE1sc0pKc3ZOVXFYYU1zYkMxcU9zOHlXRUc3cTRZUHpjMEpuQTZDcEszcU5VN0d4NWF3RUdGeEVFT0lmZnVZU3BBZzEzRmozSUNjbDJPaVptSVVUOXF4ZDFpbkRLM3RqdmQ3N3hZZFZJVXhMSGU1SGQ0Qlp2THYwVEFUeVQwSkkzSkE3S1NabDF3azl0bkc3N05PNkFqM3J6R0JWNzFaN2pKQjJ5S0xyNUhsdnVlQm11aDg2VzUzMmJmTjFzV2o1d0twME5aMEwyYVFY"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        SHOCKPROOF CLEAR SILICONE SOFT TPU CASE FOR HUAWEI HONOR
                        200 90 70 50 PRO LITE X9C A9A X9 X8B X8A X8 A7B ...
                      </a>
                      <span className="PriceTable__fullName___2MSPs">
                        SHOCKPROOF CLEAR SILICONE SOFT TPU CASE FOR HUAWEI HONOR
                        200 90 70 50 PRO LITE X9C A9A X9 X8B X8A X8 A7B X7A X7
                        X6B X6A X6 4G 5G
                      </span>
                    </div>
                    <div className="PriceTable__logo___QM_CQ">
                      <div className="PriceTable__logoWrapper___NMm-C">
                        <a
                          href="/api/prices/out?link=aHR0cHM6Ly9zLmNsaWNrLmFsaWV4cHJlc3MuY29tL3MvTmRwd3p0YkFJZ0RNbXhHYlA4ZkZrczd5VUZmc0hqemxJbVNZbUE2OGg3eGxNUmd6dWVGc1Jnb2tROWY5aGNwVkt0dHh0UUUyVnpJb3FrWEpYcnBPcjZ1UjN6djdtUDRDRE85Q2Rkb0ZmNHQwUUU1RFhQeThQZ2poejRpcmZRU0VqRUdVbXk1eUYzQTVnSjloM05ZakxNZ3FJTXFhM3pjcHE1RTJCQzNXNUViVjQwRG05YnVReFdwWnp3MWxiWDhabTJrWnROSWE0S3lEaWVOanNIN0lVNll2OFN0RUxLVnZUOXI0ZUZBYVhQR2h3SHNnYURxZE5jbVkxMTZyM0dDMll2c09vcGVpaVpQaEpyVjBjd2dPUURTa1c5M3Y3Z1ZLRHFQa1h5WkYxb1NXYTZ3U0FPZXpuTUZWbFZaREN1YkV2b011QWt1MTBNWGFoWUw3WG5mN1RJSmNBZFMzT25WbDZxMWNNMjZGOTBoNjVIcWhDUDV6OWVhc3JoMm50ODFrbnhlVDRXWWN3MDB1Q2xTdlJud2ZDUGJ4dWY5SWtiMTBHd0tTNVVjSXhYeHhEMzRLczFWUlV1cDZZd0JlVDNwb05NR2lxWXpXZlRheTBDNFI3Qkl4dmYzeGlzVGZXaXFjalJIdTJJWUFmMHhObHNnRXRSYUpzalRRampReHJxOXhHV0R6NVhqbmNscTlVckJwQkRFUVc1aXpOTjNjTUk2b3laQ3BnSXYwbFlCUW92bmVSd2d3cXh6cTFnbmZqR0pncmtoVldUNFVGQXBZNHVGd3d5RVBkWTc5dTN2aVFlWDFXTk5UdnB1Q1RMdFFVc0lCc3RNb3FXOXl3dWtLRXZMYXFiZjNZYVVpeXowS2ZMTHA0cnJqejJLYlhNaUQxRlF5R21oV2M5Szd1Z0g1VUNRdk5UMkNNQk1hSUNLMzFKSWZRVHdpQ2RyYm9aZFhFTDNUM2U4NWw5SDBHTlYxc2dhUUdoaEtaZkkzcEM0dDJKRDFpR1NPR0RtbDRVZjlPY3ZTUFdrckxsNmF3bkx5cGgxZ203cFZkREluOUdzeVB0QjhDaE1sc0pKc3ZOVXFYYU1zYkMxcU9zOHlXRUc3cTRZUHpjMEpuQTZDcEszcU5VN0d4NWF3RUdGeEVFT0lmZnVZU3BBZzEzRmozSUNjbDJPaVptSVVUOXF4ZDFpbkRLM3RqdmQ3N3hZZFZJVXhMSGU1SGQ0Qlp2THYwVEFUeVQwSkkzSkE3S1NabDF3azl0bkc3N05PNkFqM3J6R0JWNzFaN2pKQjJ5S0xyNUhsdnVlQm11aDg2VzUzMmJmTjFzV2o1d0twME5aMEwyYVFY"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <img
                            src="/img/aliexpress-logo.png"
                            alt="aliexpress logo"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="PriceTable__price___1MLQ4">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly9zLmNsaWNrLmFsaWV4cHJlc3MuY29tL3MvTmRwd3p0YkFJZ0RNbXhHYlA4ZkZrczd5VUZmc0hqemxJbVNZbUE2OGg3eGxNUmd6dWVGc1Jnb2tROWY5aGNwVkt0dHh0UUUyVnpJb3FrWEpYcnBPcjZ1UjN6djdtUDRDRE85Q2Rkb0ZmNHQwUUU1RFhQeThQZ2poejRpcmZRU0VqRUdVbXk1eUYzQTVnSjloM05ZakxNZ3FJTXFhM3pjcHE1RTJCQzNXNUViVjQwRG05YnVReFdwWnp3MWxiWDhabTJrWnROSWE0S3lEaWVOanNIN0lVNll2OFN0RUxLVnZUOXI0ZUZBYVhQR2h3SHNnYURxZE5jbVkxMTZyM0dDMll2c09vcGVpaVpQaEpyVjBjd2dPUURTa1c5M3Y3Z1ZLRHFQa1h5WkYxb1NXYTZ3U0FPZXpuTUZWbFZaREN1YkV2b011QWt1MTBNWGFoWUw3WG5mN1RJSmNBZFMzT25WbDZxMWNNMjZGOTBoNjVIcWhDUDV6OWVhc3JoMm50ODFrbnhlVDRXWWN3MDB1Q2xTdlJud2ZDUGJ4dWY5SWtiMTBHd0tTNVVjSXhYeHhEMzRLczFWUlV1cDZZd0JlVDNwb05NR2lxWXpXZlRheTBDNFI3Qkl4dmYzeGlzVGZXaXFjalJIdTJJWUFmMHhObHNnRXRSYUpzalRRampReHJxOXhHV0R6NVhqbmNscTlVckJwQkRFUVc1aXpOTjNjTUk2b3laQ3BnSXYwbFlCUW92bmVSd2d3cXh6cTFnbmZqR0pncmtoVldUNFVGQXBZNHVGd3d5RVBkWTc5dTN2aVFlWDFXTk5UdnB1Q1RMdFFVc0lCc3RNb3FXOXl3dWtLRXZMYXFiZjNZYVVpeXowS2ZMTHA0cnJqejJLYlhNaUQxRlF5R21oV2M5Szd1Z0g1VUNRdk5UMkNNQk1hSUNLMzFKSWZRVHdpQ2RyYm9aZFhFTDNUM2U4NWw5SDBHTlYxc2dhUUdoaEtaZkkzcEM0dDJKRDFpR1NPR0RtbDRVZjlPY3ZTUFdrckxsNmF3bkx5cGgxZ203cFZkREluOUdzeVB0QjhDaE1sc0pKc3ZOVXFYYU1zYkMxcU9zOHlXRUc3cTRZUHpjMEpuQTZDcEszcU5VN0d4NWF3RUdGeEVFT0lmZnVZU3BBZzEzRmozSUNjbDJPaVptSVVUOXF4ZDFpbkRLM3RqdmQ3N3hZZFZJVXhMSGU1SGQ0Qlp2THYwVEFUeVQwSkkzSkE3S1NabDF3azl0bkc3N05PNkFqM3J6R0JWNzFaN2pKQjJ5S0xyNUhsdnVlQm11aDg2VzUzMmJmTjFzV2o1d0twME5aMEwyYVFY"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        €0.79
                      </a>
                    </div>
                  </div>
                  <div className="PriceTable__row___36X6C">
                    <div className="PriceTable__name___3sDxU PriceTable__single___2ooAb">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly9zLmNsaWNrLmFsaWV4cHJlc3MuY29tL3MvZnd4MzA4Y1JEOUVueTk2M2UzS0RkWXh5SXBOajhUWFZyN3NhaUY5VW01ck1VZzFrWnRKQUxMbmZhMkxOWHRpVmdueGREOE9nNk1tdndCb3hoblNZUUdNNnIwYzA4VTRUZlg4REZHbzd2dmlDdXUzcTYyUTYwT0FoUlpjcW0zRzhQdHdNNjl4WTdUT0VXUnJ2dWxQNXVrWlE0dVFiQkJ0YVZzZHI2RWZmQXFWbXAzTzVHeVVBTlUyRG9BM2RKdWZOaERmaDdLT2xMZ0tJdlJWaER4UkVTSExtZWE2ZlRFMjZnTFVxS0VVdXVpekppTEVOckNiYXg5NERaSER1OFdoYVhNOXZRODZnemxxY2VOUE81dzN2d1htNFZyQkYzeDJsQU1FbDA2d2dOZFRTTWlUN2NORzRISWRXMEp4SDFveERnem52WkREbFBwUHU0a3lvMm5kekRWdDB1WmxGQjFlQmJvWXBwWGF0MU9HQ3hvZGtIWmFDVEMxb2s4QXhaeG1mU00xZzcwYXkxR003ZldsM2QwZm1aS1d5SllQWTdZN1JJWlM5MThDbTNnYVNLU2xJZnpGODhyaGtpZEVrajhBNTRVS3RmMXN2VWRQRWd5WWE4bDVsUXFhb0FUdkZQQ1N2Z1pJeEx3S2dMSEtoR09DbXhiY3FyS09yQUN6SXg4S2FoVDk5MUc1MnJIUDVCMEJVVUY3WmRCR2xRdnlyZ2l6RDR1ZE5SaGxUS3VBNWlOcmRRamVNVHhXRWJnZ3dkNk5WbEtqS0NNZ1pnR0JHN1pyNjdzd3A2bnZmdGJwblZYSnNVbDh4djFYSDdJb0o3WVhpeXJFeTk1NHo0ZlBjRm1FNGJtek01R29HaWZFSDN3cUpLamVZZkFoUEIyUnFXUEgxZWRPVzV5VDJZbFc4U0NzUWpiZTRQaHhRdkl4UmdaZWszalNDdmpDZGxRRG5jYm1XVnZZNlJobUJMbXpraFJKOUtDMmNYN0hLQ3o0VTVkTktMaHFMd3QwdjNaMjgxbGRsa2xSOXpPc1kyTFpmSVpEWW1lYnBYVUVqMnAxUXJWU09JR0RCRzNXd2lLbW5WYU5uS3NZTWFzWjFtYkZMV1V5QUZUdFpGTkVYV3I1b3o0REQxaGpNNFlVblZnODlZaWVObzJveWdDV1d5d3NaU1FNQTRmaVdHWHVrQnZRV21rNWR0TERVOFJCTFRpZEswa1I1Q1p4VncwZ1prMDROSnhPWXc1R2RpTWhRUHl6RVprdmdTNmtqblEyaFRJV1NMWXB5YkV3dE54NWlyTnlYcDdCYTRM"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        SHOCKPROOF CLEAR SILICONE SOFT TPU CASE FOR HUAWEI HONOR
                        200 90 70 50 PRO LITE X9C A9A X9 X8B X8A X8 A7B ...
                      </a>
                      <span className="PriceTable__fullName___2MSPs">
                        SHOCKPROOF CLEAR SILICONE SOFT TPU CASE FOR HUAWEI HONOR
                        200 90 70 50 PRO LITE X9C A9A X9 X8B X8A X8 A7B X7A X7
                        X6B X6A X6 4G 5G
                      </span>
                    </div>
                    <div className="PriceTable__logo___QM_CQ">
                      <div className="PriceTable__logoWrapper___NMm-C">
                        <a
                          href="/api/prices/out?link=aHR0cHM6Ly9zLmNsaWNrLmFsaWV4cHJlc3MuY29tL3MvZnd4MzA4Y1JEOUVueTk2M2UzS0RkWXh5SXBOajhUWFZyN3NhaUY5VW01ck1VZzFrWnRKQUxMbmZhMkxOWHRpVmdueGREOE9nNk1tdndCb3hoblNZUUdNNnIwYzA4VTRUZlg4REZHbzd2dmlDdXUzcTYyUTYwT0FoUlpjcW0zRzhQdHdNNjl4WTdUT0VXUnJ2dWxQNXVrWlE0dVFiQkJ0YVZzZHI2RWZmQXFWbXAzTzVHeVVBTlUyRG9BM2RKdWZOaERmaDdLT2xMZ0tJdlJWaER4UkVTSExtZWE2ZlRFMjZnTFVxS0VVdXVpekppTEVOckNiYXg5NERaSER1OFdoYVhNOXZRODZnemxxY2VOUE81dzN2d1htNFZyQkYzeDJsQU1FbDA2d2dOZFRTTWlUN2NORzRISWRXMEp4SDFveERnem52WkREbFBwUHU0a3lvMm5kekRWdDB1WmxGQjFlQmJvWXBwWGF0MU9HQ3hvZGtIWmFDVEMxb2s4QXhaeG1mU00xZzcwYXkxR003ZldsM2QwZm1aS1d5SllQWTdZN1JJWlM5MThDbTNnYVNLU2xJZnpGODhyaGtpZEVrajhBNTRVS3RmMXN2VWRQRWd5WWE4bDVsUXFhb0FUdkZQQ1N2Z1pJeEx3S2dMSEtoR09DbXhiY3FyS09yQUN6SXg4S2FoVDk5MUc1MnJIUDVCMEJVVUY3WmRCR2xRdnlyZ2l6RDR1ZE5SaGxUS3VBNWlOcmRRamVNVHhXRWJnZ3dkNk5WbEtqS0NNZ1pnR0JHN1pyNjdzd3A2bnZmdGJwblZYSnNVbDh4djFYSDdJb0o3WVhpeXJFeTk1NHo0ZlBjRm1FNGJtek01R29HaWZFSDN3cUpLamVZZkFoUEIyUnFXUEgxZWRPVzV5VDJZbFc4U0NzUWpiZTRQaHhRdkl4UmdaZWszalNDdmpDZGxRRG5jYm1XVnZZNlJobUJMbXpraFJKOUtDMmNYN0hLQ3o0VTVkTktMaHFMd3QwdjNaMjgxbGRsa2xSOXpPc1kyTFpmSVpEWW1lYnBYVUVqMnAxUXJWU09JR0RCRzNXd2lLbW5WYU5uS3NZTWFzWjFtYkZMV1V5QUZUdFpGTkVYV3I1b3o0REQxaGpNNFlVblZnODlZaWVObzJveWdDV1d5d3NaU1FNQTRmaVdHWHVrQnZRV21rNWR0TERVOFJCTFRpZEswa1I1Q1p4VncwZ1prMDROSnhPWXc1R2RpTWhRUHl6RVprdmdTNmtqblEyaFRJV1NMWXB5YkV3dE54NWlyTnlYcDdCYTRM"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <img
                            src="/img/aliexpress-logo.png"
                            alt="aliexpress logo"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="PriceTable__price___1MLQ4">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly9zLmNsaWNrLmFsaWV4cHJlc3MuY29tL3MvZnd4MzA4Y1JEOUVueTk2M2UzS0RkWXh5SXBOajhUWFZyN3NhaUY5VW01ck1VZzFrWnRKQUxMbmZhMkxOWHRpVmdueGREOE9nNk1tdndCb3hoblNZUUdNNnIwYzA4VTRUZlg4REZHbzd2dmlDdXUzcTYyUTYwT0FoUlpjcW0zRzhQdHdNNjl4WTdUT0VXUnJ2dWxQNXVrWlE0dVFiQkJ0YVZzZHI2RWZmQXFWbXAzTzVHeVVBTlUyRG9BM2RKdWZOaERmaDdLT2xMZ0tJdlJWaER4UkVTSExtZWE2ZlRFMjZnTFVxS0VVdXVpekppTEVOckNiYXg5NERaSER1OFdoYVhNOXZRODZnemxxY2VOUE81dzN2d1htNFZyQkYzeDJsQU1FbDA2d2dOZFRTTWlUN2NORzRISWRXMEp4SDFveERnem52WkREbFBwUHU0a3lvMm5kekRWdDB1WmxGQjFlQmJvWXBwWGF0MU9HQ3hvZGtIWmFDVEMxb2s4QXhaeG1mU00xZzcwYXkxR003ZldsM2QwZm1aS1d5SllQWTdZN1JJWlM5MThDbTNnYVNLU2xJZnpGODhyaGtpZEVrajhBNTRVS3RmMXN2VWRQRWd5WWE4bDVsUXFhb0FUdkZQQ1N2Z1pJeEx3S2dMSEtoR09DbXhiY3FyS09yQUN6SXg4S2FoVDk5MUc1MnJIUDVCMEJVVUY3WmRCR2xRdnlyZ2l6RDR1ZE5SaGxUS3VBNWlOcmRRamVNVHhXRWJnZ3dkNk5WbEtqS0NNZ1pnR0JHN1pyNjdzd3A2bnZmdGJwblZYSnNVbDh4djFYSDdJb0o3WVhpeXJFeTk1NHo0ZlBjRm1FNGJtek01R29HaWZFSDN3cUpLamVZZkFoUEIyUnFXUEgxZWRPVzV5VDJZbFc4U0NzUWpiZTRQaHhRdkl4UmdaZWszalNDdmpDZGxRRG5jYm1XVnZZNlJobUJMbXpraFJKOUtDMmNYN0hLQ3o0VTVkTktMaHFMd3QwdjNaMjgxbGRsa2xSOXpPc1kyTFpmSVpEWW1lYnBYVUVqMnAxUXJWU09JR0RCRzNXd2lLbW5WYU5uS3NZTWFzWjFtYkZMV1V5QUZUdFpGTkVYV3I1b3o0REQxaGpNNFlVblZnODlZaWVObzJveWdDV1d5d3NaU1FNQTRmaVdHWHVrQnZRV21rNWR0TERVOFJCTFRpZEswa1I1Q1p4VncwZ1prMDROSnhPWXc1R2RpTWhRUHl6RVprdmdTNmtqblEyaFRJV1NMWXB5YkV3dE54NWlyTnlYcDdCYTRM"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        €1.18
                      </a>
                    </div>
                  </div>
                  <div className="PriceTable__row___36X6C">
                    <div className="PriceTable__name___3sDxU PriceTable__single___2ooAb">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly9zLmNsaWNrLmFsaWV4cHJlc3MuY29tL3MvTmRwd3p0YkFJZ0RNbXhHYlA4ZkZrczd5VUZmc0hqemxJbVNZbUE2OGg3eGxNUmd6dWVGc1Jnb2tROWY5aGNwVkt0dHh0UUUyVnpJb3FrWEpYcnBPcjZ1UjN6djdtUDRDRE85Q2Rkb0ZmNHQwUUU1RFhQeThQZ2poejRpcmZRU0VqRUdVbXk1eUYzQTVnSjloM05ZakxNZ3FJTXFhM3pjcHE1RTJCQzNXNUViVjQwRG05YnVReFdwWnp3MWxiWDhabTJrWnROSWE0S3lEaWVOanNIN0lVNll2OFN0RUxLVnZUOXI0ZUZBYVhQR2h3SHNnYURxZE5jbVkxMTZyM0dDMll2c09vcGVpaVpQaEpyVjBjd2dPUURTa1c5M3Y3Z1ZLRHFQa1h5WkYxb1NXYTZ3U0FPZXpuTUZWbFZaYnhCQlR0c1JieW9RR0VTTElBMDRvVWMydXA5TFdOUWI4dVNmRmNYSVBhb3laVmxxdjVsRldPVjVIVHduOWZyOU5zMnl0dEs5UklSZldZYUlPYmRORFF2NjBOVVBTYnduWmwzN0NRbXlRQ05ocmlSRzFRaXN0Z0s0V0Zvc04xNW1vYXFnOG8yWEZ0NGNJczhTaHk0TGVBUDVNcTQ1S3l5OXBZY1Z2UHdSUnh6dk0zNzF2dW5Ccmp5VU0xVllzZHM4OWtjM0luNG1XVzFmMWgyOGhQb1VURFdSSG93eUZFTkt1WEtmMm9vRGNVQWlEcG5ST3YycXhSajBYUHpNNFo0ZVZCRTFyVTJ5TTAzaVp5Yk5PemFDZFZBTXR2MDQ2eHd6T0xiS2Y3RUhCRDBhenVESDNtek5jeFBSNElnN0Z3Z2h4c1dFNnp2UlBiQUcxbVJRVkd5Tld6UnhRdmthdU9QRUV5eElmdUpoZHlLSXh5RGU1aEkyeUE1VHJKZjFwRnVCdWNJdWVlWG5Rc3QxUWJXakdZYmdvSFVhY0VBYjQ5M2h3WERlOTRuZEZYdkJSOVRYdEF2Q2tRSUtoVlpyYUR5VFIzaThhbHNFOXFzVGNKN3RXaEtzZ1NVTzAyVzJzZDF1MERGTDM5SmhxUmJSMk83cUN1WkFjQzN6R1duUzNvQ29oSmhsMGVuN09VOVJvRGZwVTRybG81eDRxbkc1ZXRoUkFMeElBM0ZwalgwS0x6SXBGMGRpUGpOallDaHRmclIwcjZxdnZRczRzemxLa0xSWFdCNk9WWU5pNG5mNUxNbW1QM3dRVWtYV21PMlNYVTRQWW0zZDJtanllR0pDNWFSREZYOTBxMUVEQlZlNnNVdWhSZWxoNWdpb3pUVVdseGRUdXQ2TGptTWplSzIxc3ExUWFC"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        SHOCKPROOF CLEAR SILICONE SOFT TPU CASE FOR HUAWEI HONOR
                        200 90 70 50 PRO LITE X9C A9A X9 X8B X8A X8 A7B ...
                      </a>
                      <span className="PriceTable__fullName___2MSPs">
                        SHOCKPROOF CLEAR SILICONE SOFT TPU CASE FOR HUAWEI HONOR
                        200 90 70 50 PRO LITE X9C A9A X9 X8B X8A X8 A7B X7A X7
                        X6B X6A X6 4G 5G
                      </span>
                    </div>
                    <div className="PriceTable__logo___QM_CQ">
                      <div className="PriceTable__logoWrapper___NMm-C">
                        <a
                          href="/api/prices/out?link=aHR0cHM6Ly9zLmNsaWNrLmFsaWV4cHJlc3MuY29tL3MvTmRwd3p0YkFJZ0RNbXhHYlA4ZkZrczd5VUZmc0hqemxJbVNZbUE2OGg3eGxNUmd6dWVGc1Jnb2tROWY5aGNwVkt0dHh0UUUyVnpJb3FrWEpYcnBPcjZ1UjN6djdtUDRDRE85Q2Rkb0ZmNHQwUUU1RFhQeThQZ2poejRpcmZRU0VqRUdVbXk1eUYzQTVnSjloM05ZakxNZ3FJTXFhM3pjcHE1RTJCQzNXNUViVjQwRG05YnVReFdwWnp3MWxiWDhabTJrWnROSWE0S3lEaWVOanNIN0lVNll2OFN0RUxLVnZUOXI0ZUZBYVhQR2h3SHNnYURxZE5jbVkxMTZyM0dDMll2c09vcGVpaVpQaEpyVjBjd2dPUURTa1c5M3Y3Z1ZLRHFQa1h5WkYxb1NXYTZ3U0FPZXpuTUZWbFZaYnhCQlR0c1JieW9RR0VTTElBMDRvVWMydXA5TFdOUWI4dVNmRmNYSVBhb3laVmxxdjVsRldPVjVIVHduOWZyOU5zMnl0dEs5UklSZldZYUlPYmRORFF2NjBOVVBTYnduWmwzN0NRbXlRQ05ocmlSRzFRaXN0Z0s0V0Zvc04xNW1vYXFnOG8yWEZ0NGNJczhTaHk0TGVBUDVNcTQ1S3l5OXBZY1Z2UHdSUnh6dk0zNzF2dW5Ccmp5VU0xVllzZHM4OWtjM0luNG1XVzFmMWgyOGhQb1VURFdSSG93eUZFTkt1WEtmMm9vRGNVQWlEcG5ST3YycXhSajBYUHpNNFo0ZVZCRTFyVTJ5TTAzaVp5Yk5PemFDZFZBTXR2MDQ2eHd6T0xiS2Y3RUhCRDBhenVESDNtek5jeFBSNElnN0Z3Z2h4c1dFNnp2UlBiQUcxbVJRVkd5Tld6UnhRdmthdU9QRUV5eElmdUpoZHlLSXh5RGU1aEkyeUE1VHJKZjFwRnVCdWNJdWVlWG5Rc3QxUWJXakdZYmdvSFVhY0VBYjQ5M2h3WERlOTRuZEZYdkJSOVRYdEF2Q2tRSUtoVlpyYUR5VFIzaThhbHNFOXFzVGNKN3RXaEtzZ1NVTzAyVzJzZDF1MERGTDM5SmhxUmJSMk83cUN1WkFjQzN6R1duUzNvQ29oSmhsMGVuN09VOVJvRGZwVTRybG81eDRxbkc1ZXRoUkFMeElBM0ZwalgwS0x6SXBGMGRpUGpOallDaHRmclIwcjZxdnZRczRzemxLa0xSWFdCNk9WWU5pNG5mNUxNbW1QM3dRVWtYV21PMlNYVTRQWW0zZDJtanllR0pDNWFSREZYOTBxMUVEQlZlNnNVdWhSZWxoNWdpb3pUVVdseGRUdXQ2TGptTWplSzIxc3ExUWFC"
                          target="_blank"
                          rel="noopener noreferrer nofollow"
                        >
                          <img
                            src="/img/aliexpress-logo.png"
                            alt="aliexpress logo"
                            loading="lazy"
                          />
                        </a>
                      </div>
                    </div>
                    <div className="PriceTable__price___1MLQ4">
                      <a
                        href="/api/prices/out?link=aHR0cHM6Ly9zLmNsaWNrLmFsaWV4cHJlc3MuY29tL3MvTmRwd3p0YkFJZ0RNbXhHYlA4ZkZrczd5VUZmc0hqemxJbVNZbUE2OGg3eGxNUmd6dWVGc1Jnb2tROWY5aGNwVkt0dHh0UUUyVnpJb3FrWEpYcnBPcjZ1UjN6djdtUDRDRE85Q2Rkb0ZmNHQwUUU1RFhQeThQZ2poejRpcmZRU0VqRUdVbXk1eUYzQTVnSjloM05ZakxNZ3FJTXFhM3pjcHE1RTJCQzNXNUViVjQwRG05YnVReFdwWnp3MWxiWDhabTJrWnROSWE0S3lEaWVOanNIN0lVNll2OFN0RUxLVnZUOXI0ZUZBYVhQR2h3SHNnYURxZE5jbVkxMTZyM0dDMll2c09vcGVpaVpQaEpyVjBjd2dPUURTa1c5M3Y3Z1ZLRHFQa1h5WkYxb1NXYTZ3U0FPZXpuTUZWbFZaYnhCQlR0c1JieW9RR0VTTElBMDRvVWMydXA5TFdOUWI4dVNmRmNYSVBhb3laVmxxdjVsRldPVjVIVHduOWZyOU5zMnl0dEs5UklSZldZYUlPYmRORFF2NjBOVVBTYnduWmwzN0NRbXlRQ05ocmlSRzFRaXN0Z0s0V0Zvc04xNW1vYXFnOG8yWEZ0NGNJczhTaHk0TGVBUDVNcTQ1S3l5OXBZY1Z2UHdSUnh6dk0zNzF2dW5Ccmp5VU0xVllzZHM4OWtjM0luNG1XVzFmMWgyOGhQb1VURFdSSG93eUZFTkt1WEtmMm9vRGNVQWlEcG5ST3YycXhSajBYUHpNNFo0ZVZCRTFyVTJ5TTAzaVp5Yk5PemFDZFZBTXR2MDQ2eHd6T0xiS2Y3RUhCRDBhenVESDNtek5jeFBSNElnN0Z3Z2h4c1dFNnp2UlBiQUcxbVJRVkd5Tld6UnhRdmthdU9QRUV5eElmdUpoZHlLSXh5RGU1aEkyeUE1VHJKZjFwRnVCdWNJdWVlWG5Rc3QxUWJXakdZYmdvSFVhY0VBYjQ5M2h3WERlOTRuZEZYdkJSOVRYdEF2Q2tRSUtoVlpyYUR5VFIzaThhbHNFOXFzVGNKN3RXaEtzZ1NVTzAyVzJzZDF1MERGTDM5SmhxUmJSMk83cUN1WkFjQzN6R1duUzNvQ29oSmhsMGVuN09VOVJvRGZwVTRybG81eDRxbkc1ZXRoUkFMeElBM0ZwalgwS0x6SXBGMGRpUGpOallDaHRmclIwcjZxdnZRczRzemxLa0xSWFdCNk9WWU5pNG5mNUxNbW1QM3dRVWtYV21PMlNYVTRQWW0zZDJtanllR0pDNWFSREZYOTBxMUVEQlZlNnNVdWhSZWxoNWdpb3pUVVdseGRUdXQ2TGptTWplSzIxc3ExUWFC"
                        target="_blank"
                        rel="noopener noreferrer nofollow"
                      >
                        €1.38
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="specs" className="PropertyGroups__propertyGroups___1NzWd">
        <div id="group_design" className="Group__group___1bHuf">
          <div className="Group__groupContent___34APs">
            <h2>
              <span className="Group__icon___1qsH3">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 24, height: 24, fill: "black" }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_smartphone</title>
                    <path d="M22 2h-12c-1.103 0-2 0.897-2 2v24c0 1.103 0.897 2 2 2h12c1.103 0 2-0.897 2-2v-24c0-1.103-0.897-2-2-2zM9 6.010h14v19.017h-14v-19.017zM9 4c0-0.552 0.449-1 1-1h12c0.551 0 1 0.448 1 1v1.010h-14v-1.010zM23 28c0 0.552-0.449 1-1 1h-12c-0.551 0-1-0.448-1-1v-1.973h14v1.973zM14.5 27.995h3c0.276 0 0.5-0.224 0.5-0.5s-0.224-0.5-0.5-0.5h-3c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5z" />
                  </svg>
                </span>
              </span>
              Design
            </h2>
            <div className="Group__propertiesContainer___3-B5C">
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/weight"
                  >
                    <span className="Property__label___33tiu">poids</span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">189 g</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "21%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Nous préférons un poids plus léger car l'appareil sera plus
                  facile à porter. Un poids peu élevé est aussi un avantage pour
                  l'électroménager et d'autres produits car ils seront beaucoup
                  plus faciles à transporter.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/thickness"
                  >
                    <span className="Property__label___33tiu">épaisseur</span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">8 mm</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "18%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  L'épaisseur (ou profondeur) du produit.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/width"
                  >
                    <span className="Property__label___33tiu">largeur</span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">75.5 mm</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "15%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La largeur représente la dimension horizontale du produit.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/height"
                  >
                    <span className="Property__label___33tiu">hauteur</span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">162.8 mm</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "73%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La hauteur représente la dimension verticale du produit.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/water-resistance"
                  >
                    <span className="Property__label___33tiu">
                      résistance à l'eau
                    </span>
                  </a>
                  <div className="Value__value___qfSvr ranked">
                    <p className="Number__number___Mp4lk">Résistant à l'eau</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "66%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le niveau de résistance à l'eau de l'appareil.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/ip-rating-r"
                  >
                    <span className="Property__label___33tiu">
                      indice de protection (IP)
                    </span>
                  </a>
                  <div className="Value__value___qfSvr ranked">
                    <p className="Number__number___Mp4lk">IP65</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "51%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le premier chiffre de l'indice IP fait référence à la
                  protection contre la poussière, tandis que le deuxième chiffre
                  fait référence à la protection contre les liquides. Par
                  exemple, un premier nombre de 6 indique qu'il est complètement
                  étanche à la poussière, et un deuxième nombre de 7 indique que
                  l'appareil peut supporter une immersion totale dans l'eau.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/waterproof-depth"
                  >
                    <span className="Property__label___33tiu">
                      Niveau d’imperméabilité
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  L'imperméabilité indique le niveau de protection d'un appareil
                  contre les infiltrations d'eau et la pression. Les appareils
                  qui peuvent résister à une pression supérieure sont plus
                  adaptés aux nageurs et aux plongeurs.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/volume"
                  >
                    <span className="Property__label___33tiu">volume</span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">98.33 cm³</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "15%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le volume est la quantité d'espace en trois dimension occupé
                  par le produit.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/min-temp"
                  >
                    <span className="Property__label___33tiu">
                      température d’opération la plus bas
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La température minimale à laquelle l'appareil peut fonctionner
                  à un niveau optimal.
                </div>
              </div>
            </div>
            <div className="Group__buttonContainer___1XtXa">
              <button aria-label="Load more properties in Design group">
                + Voir plus +
              </button>
            </div>
          </div>
        </div>
        <div id="group_display" className="Group__group___1bHuf">
          <div className="Group__groupContent___34APs">
            <h2>
              <span className="Group__icon___1qsH3">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 24, height: 24, fill: "black" }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_image</title>
                    <path d="M0 4v24h32v-24h-32zM31 5v14.895l-6.761-5.081-3.57 1.903-4.981-2.96-14.688 9.092v-17.849h30zM1 27v-2.976l14.699-9.098 4.944 2.938 3.504-1.87 6.853 5.151v5.855h-30zM21.823 13.465c1.737 0 3.15-1.416 3.15-3.157s-1.413-3.156-3.15-3.156-3.15 1.416-3.15 3.156c0 1.741 1.413 3.157 3.15 3.157zM21.823 8.151c1.186 0 2.15 0.968 2.15 2.156s-0.965 2.157-2.15 2.157-2.15-0.968-2.15-2.157c0-1.188 0.965-2.156 2.15-2.156z" />
                  </svg>
                </span>
              </span>
              Écran
            </h2>
            <div className="Group__propertiesContainer___3-B5C">
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/screen-size"
                  >
                    <span className="Property__label___33tiu">
                      taille d'écran
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">6.78"</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "56%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La taille de l'écran (mesure diagonale).
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/mobile-display-tech"
                  >
                    <span className="Property__label___33tiu">
                      type d'écran
                    </span>
                  </a>
                  <div className="Value__value___qfSvr ranked">
                    <p className="Number__number___Mp4lk">OLED/AMOLED</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "100%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le type de technologie utilisée dans l'écran.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/pixel-density"
                  >
                    <span className="Property__label___33tiu">
                      densité de pixels
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">437 ppi</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "44%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La densité de pixels est une mesure de la résolution d'un
                  écran, exprimée par le nombre de pixels par pouce (PPP) sur
                  l'écran. Une densité plus élevée apportera plus de clarté et
                  de netteté aux images qui apparaissent à l'écran, ce qui
                  améliore l'expérience de visionnage.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/resolution"
                  >
                    <span className="Property__label___33tiu">résolution</span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">1224 x 2700 px</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "35%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La résolution indique le nombre maximum de pixels qui peuvent
                  apparaître sur un écran. Il est exprimé en nombre de pixels
                  sur l'axe horizontal et en nombre de pixel sur l'axe vertical.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/refresh-rate"
                  >
                    <span className="Property__label___33tiu">
                      Taux de rafraîchissement
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">120Hz</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "33%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  C'est la fréquence à laquelle un écran est rafraîchi (1 Hz =
                  une fois par seconde). Une fréquence de rafraîchissement plus
                  elevée permet un rendu plus fluide des animations UI et de la
                  lecture vidéo.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/touch-sampling-rate"
                  >
                    <span className="Property__label___33tiu">
                      taux d'échantillonage tactile
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le taux d'échantillonnage tactile est la vitesse à laquelle
                  l'écran reconnaît ton toucher et y répond en générant la
                  prochaine image. Aussi appelé taux de rafraîchissement
                  tactile.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/luminance"
                  >
                    <span className="Property__label___33tiu">
                      luminosité (typique)
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le nit est une unité de mesure de la quantité de lumière émise
                  par un écran, égale à un candela par mètre carré. Un écran
                  plus éclairé permet une lecture plus confortable de ce qui est
                  affiché, même par temps ensoleillé.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/gorilla-glas"
                  >
                    <span className="Property__label___33tiu">
                      possède un écran anti-casse en verre
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Un écran anti-casse en verre (tel que Corning Gorilla Glass ou
                  Asahi Dragontrail Glass) est fin et léger, et peut résister à
                  de grands niveaux de forces.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/hdr10-display"
                  >
                    <span className="Property__label___33tiu">
                      compatible HDR10
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  HDR10 est une technologie vidéo HDR (en français, imagerie à
                  grande gamme dynamique). Comparée à la technologie SDR (image
                  à gamme dynamique standard), HDR10 offre de plus grands
                  contraste et profondeur de couleurs, ce qui offre un rendu
                  plus proche de la realité.
                </div>
              </div>
            </div>
            <div className="Group__buttonContainer___1XtXa">
              <button aria-label="Load more properties in Écran group">
                + Voir plus +
              </button>
            </div>
          </div>
        </div>
        <div id="group_performance" className="Group__group___1bHuf">
          <div className="Group__groupContent___34APs">
            <h2>
              <span className="Group__icon___1qsH3">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 24, height: 24, fill: "black" }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_chip</title>
                    <path d="M7.831 24.22h16.3v-16.3h-16.3v16.3zM8.831 8.92h14.3v14.3h-14.3v-14.3zM30 8.827v-1h-2.232v-1.545c0-1.103-0.897-2-2-2h-1.525v-2.212h-1v2.212h-1.213v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.212v-2.212h-1v2.212h-1.213v-2.212h-1v2.212h-1.564c-1.103 0-2 0.897-2 2v1.545h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.212h-2.193v1h2.193v1.213h-2.193v1h2.193v1.212h-2.193v1h2.193v1.545c0 1.103 0.897 2 2 2h1.564v2.212h1v-2.212h1.213v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.212v2.212h1v-2.212h1.525c1.103 0 2-0.897 2-2v-1.545h2.233v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.212h2.232v-1h-2.232v-1.213h2.232zM26.768 25.858c0 0.551-0.449 1-1 1h-19.575c-0.551 0-1-0.449-1-1v-19.576c0-0.551 0.449-1 1-1h19.575c0.551 0 1 0.449 1 1v19.576z" />
                  </svg>
                </span>
              </span>
              Performance
            </h2>
            <div className="Group__propertiesContainer___3-B5C">
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/internal-storage"
                  >
                    <span className="Property__label___33tiu">
                      espace de stockage
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">512GB</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "49%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  L'espace de stockage interne est l'espace initialement
                  disponible sur un appareil pour les données systèmes, les
                  applications et les données utilisateurs. Un espace de
                  stockage important permet de sauvegarder plus de fichiers et
                  d'applications sur ton appareil.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/ram"
                  >
                    <span className="Property__label___33tiu">
                      mémoire vive (RAM)
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">12GB</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "49%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La mémoire vive (RAM) est une forme de mémoire utilisée pour
                  stocker les données de travail et le code machine. Avoir plus
                  de RAM est particulièrement utile pour le multitâche, ça te
                  permet de faire tourner plus de programmes à la fois ou
                  d'avoir plus d'onglets ouverts dans ton navigateur.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/antutu"
                  >
                    <span className="Property__label___33tiu">
                      Score du benchmark AnTuTu
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  AnTuTu est un des benchmarks les plus importants pour les
                  appareils Android. Le score reflète la performance générale
                  d'un appareil en additionnant les résultats de tests
                  individuels portant entre autres sur la vitesse de la RAM, la
                  performance du CPU et la performance graphique en 2D &amp; 3D.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/gpu-name"
                  >
                    <span className="Property__label___33tiu">nom du GPU</span>
                  </a>
                  <div className="Value__value___qfSvr string">
                    <div className="String__string___2hvCr">
                      <p>
                        Adreno 710
                        <br />
                        <span className="String__rivalName___3FrwW">
                          Honor X9c 5G
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le nom du GPU intégré.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/3dmark-wildlife"
                  >
                    <span className="Property__label___33tiu">
                      benchmark 3DMark Wild Life
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Wild Life est un outil de benchmark multiplateforme développé
                  par 3DMark qui teste la performance graphique d'un appareil
                  (en utilisant l'API Vulkan sur Android/Windows et l'API Metal
                  sur iOS). Source: 3DMark.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/3dmark-wildlife-unlimited"
                  >
                    <span className="Property__label___33tiu">
                      benchmark 3DMark Wild Life Unlimited
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Wild Life est un outil de benchmark multiplateforme développé
                  par 3DMark qui teste la performance graphique d'un appareil
                  (en utilisant l'API Vulkan sur Android/Windows et l'API Metal
                  sur iOS). La version Unlimited tourne hors écran afin que la
                  résolution de celui-ci n'affecte pas le score. Source: 3DMark.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/3dmark-wildlife-extreme"
                  >
                    <span className="Property__label___33tiu">
                      benchmark 3DMark Wild Life Extreme
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Wild Life est un outil de benchmark multiplateforme développé
                  par 3DMark qui teste la performance graphique d'un appareil
                  (en utilisant l'API Vulkan sur Android/Windows et l'API Metal
                  sur iOS). La version Extreme est bien plus exigeante
                  graphiquement que la version standard de l'outil Wild Life.
                  Source: 3DMark.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/passmark-android3-performance"
                  >
                    <span className="Property__label___33tiu">
                      Test de performance PCMark Work 3.0
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Work 3.0 est un benchmark pour Android (développé par UL
                  Solutions) qui teste les performances quotidiennes d'un
                  appareil (par exemple la navigation Web et la retouche photo).
                  Source : UL Solutions.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/passmark-android3-battery"
                  >
                    <span className="Property__label___33tiu">
                      Test de batterie PCMark Work 3.0
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  PCMark Work 3.0 est un benchmark pour Android (développé par
                  UL Solutions) qui teste l'autonomie de la batterie d'un
                  appareil. Source : UL Solutions.
                </div>
              </div>
            </div>
            <div className="Group__buttonContainer___1XtXa">
              <button aria-label="Load more properties in Performance group">
                + Voir plus +
              </button>
            </div>
          </div>
        </div>
        <div id="group_cameras" className="Group__group___1bHuf">
          <div className="Group__groupContent___34APs">
            <h2>
              <span className="Group__icon___1qsH3">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 24, height: 24, fill: "black" }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_shutter</title>
                    <path d="M10.028 12.424l-5.566 9.64c-0.961-1.842-1.463-3.887-1.463-5.997 0-1.243 0.174-2.466 0.518-3.643h6.511zM11.758 11.424h-8.969c-0.511 1.453-0.789 3.016-0.789 4.644 0 2.957 0.917 5.7 2.482 7.959l7.276-12.603zM16.578 3.081c3.348 0.148 6.501 1.581 8.818 4.003l-3.254 5.636-5.564-9.639zM16 2.068c-0.382 0-0.759 0.017-1.134 0.045l7.276 12.604 4.483-7.765c-2.567-2.99-6.375-4.884-10.625-4.884v0zM12.916 3.437l3.253 5.635h-11.129c1.798-2.809 4.626-4.844 7.876-5.635zM13.417 2.307c-4.462 0.832-8.18 3.78-10.071 7.765h14.554l-4.483-7.765zM27.539 10.071c0.961 1.842 1.463 3.887 1.463 5.997 0 1.244-0.174 2.466-0.518 3.644h-6.511l5.566-9.641zM27.518 8.109l-7.276 12.603h8.969c0.511-1.453 0.789-3.016 0.789-4.644 0-2.957-0.917-5.699-2.482-7.959v0zM26.96 23.063c-1.798 2.81-4.626 4.845-7.876 5.636l-3.254-5.636h11.13zM28.654 22.063h-14.554l4.483 7.766c4.463-0.832 8.18-3.781 10.071-7.766v0zM9.858 19.417l5.564 9.638c-3.347-0.149-6.501-1.582-8.817-4.003l3.253-5.635zM9.858 17.419l-4.483 7.764c2.567 2.99 6.375 4.884 10.625 4.884 0.382 0 0.759-0.016 1.134-0.045l-7.276-12.603z" />
                  </svg>
                </span>
              </span>
              Appareils photo
            </h2>
            <div className="Group__propertiesContainer___3-B5C">
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/megapixels"
                  >
                    <span className="Property__label___33tiu">
                      mégapixels (appareil photo principal)
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">108 MP &amp; 5 MP</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "35%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le nombre de mégapixels détermine la résolution des images
                  capturées avec l'appareil photo principal. Un nombre de
                  mégapixels élevé indique que l'appareil photo est en mesure de
                  capturer plus de détails. Cependant, ce n'est pas le seul
                  facteur à prendre en compte pour obtenir des images de
                  qualité.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/front-camera-megapixel"
                  >
                    <span className="Property__label___33tiu">
                      mégapixels (caméra frontale)
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">16MP</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "7%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le nombre de mégapixels détermine la résolution de l'image
                  capturée avec la caméra frontale. Plus le nombre de mégapixels
                  est élevé, plus la caméra frontale sera en mesure de capturer
                  des détails, ce qui est essentiel pour la prise de selfie en
                  haute résolution.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/optical-image-stabilization"
                  >
                    <span className="Property__label___33tiu">
                      doté(e) d'une stabilisation optique d'images intégrée
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La stabilisation optique d'images utilise des capteurs
                  gyroscopiques pour détecter les vibrations de l'appareil.
                  L'objectif ajuste le trajet optique en conséquence,
                  garantissant que tout flou de mouvement soit corrigé avant que
                  le détecteur ne capture l'image.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/video-recording"
                  >
                    <span className="Property__label___33tiu">
                      enregistrement de vidéo
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">2160 x 30 fps</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "24%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La résolution maximale disponible pours les vidéos prises avec
                  l'appareil photo principal. Même s'il est peut-être possible
                  de choisir entre plusieurs fréquences d'image, ces
                  enregistrements sont souvent d'une qualité inférieure.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/wide-aperture"
                  >
                    <span className="Property__label___33tiu">
                      plus grande ouverture
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">f/2.2 &amp; f/1.8</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "38%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  C'est la ouverture la plus large disponible à la focale
                  minimale. Avec une ouverture plus large, le capteur peut
                  prendre plus de lumière. Cela permet d'éviter le flou en
                  employant une vitesse d'obturation plus rapide. En plus, ces
                  lentilles donnent une étroite profondeur de champ permettant
                  de se focaliser sur le sujet et estomper l'arrière-plan.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/dual-flash"
                  >
                    <span className="Property__label___33tiu">
                      possède un flash LED dual-tone
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Un flash "dual-tone" possède des lumières LED de différentes
                  températures de couleurs qui apportent un meilleur équilibre
                  de couleurs aux images et vidéos.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/back-illuminated-sensor"
                  >
                    <span className="Property__label___33tiu">
                      doté(e) d'un capteur rétroéclairé
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  BSI (Backside Illumination Sensor) est un type de capteur
                  d'image qui offre une meilleure qualité dans des conditions de
                  faible éclairage et une meilleure netteté globale et qualité
                  d'image.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/continuous-af"
                  >
                    <span className="Property__label___33tiu">
                      doté(e) d'un autofocus en continu lors de l'enregistrement
                      des vidéos
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Lors de l'enregistrement des vidéos, elles restent focalisées
                  et nettes.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/phase-detection-photo"
                  >
                    <span className="Property__label___33tiu">
                      Un autofocus plus rapide grace à une focalisation
                      automatique par détection de phase lors de la prise des
                      photos
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Un autofocus par détection de phase est beaucoup plus rapide
                  qu'un autofocus par détection de contraste et permet de
                  capturer des images plus nettes.
                </div>
              </div>
            </div>
            <div className="Group__buttonContainer___1XtXa">
              <button aria-label="Load more properties in Appareils photo group">
                + Voir plus +
              </button>
            </div>
          </div>
        </div>
        <div id="group_operating_system" className="Group__group___1bHuf">
          <div className="Group__groupContent___34APs">
            <h2>
              <span className="Group__icon___1qsH3">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 24, height: 24, fill: "black" }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_phone_pc</title>
                    <path d="M30.136 9.976h-9.109c-1.103 0-2 0.897-2 2v18c0 1.103 0.897 2 2 2h9.109c1.103 0 2-0.897 2-2v-18c0-1.103-0.897-2-2-2zM20.027 13.318h11.109v14.568h-11.109v-14.568zM21.027 10.976h9.109c0.551 0 1 0.449 1 1v0.341h-11.109v-0.341c0-0.551 0.449-1 1-1zM30.136 30.976h-9.109c-0.551 0-1-0.449-1-1v-1.090h11.109v1.090c0 0.552-0.448 1-1 1zM26.792 29.417h-2.422c-0.276 0-0.5 0.224-0.5 0.5s0.224 0.5 0.5 0.5h2.422c0.276 0 0.5-0.224 0.5-0.5s-0.223-0.5-0.5-0.5zM0.987 5.975h26v3.034h1v-3.534c0-0.276-0.224-0.5-0.5-0.5h-27c-0.276 0-0.5 0.224-0.5 0.5v17c0 0.276 0.224 0.5 0.5 0.5h13v2.069l-3.5 0.913 0.253 0.968 3.747-0.978 3.747 0.978 0.253-0.968-3.5-0.913v-2.069h3.513v-1h-17.013v-0.985h17.013v-1h-17.013v-14.015z" />
                  </svg>
                </span>
              </span>
              Système d'opération
            </h2>
            <div className="Group__propertiesContainer___3-B5C">
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/android-version"
                  >
                    <span className="Property__label___33tiu">
                      version Android
                    </span>
                  </a>
                  <div className="Value__value___qfSvr ranked">
                    <p className="Number__number___Mp4lk">Android 14</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "90%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Une version plus récente d'Android offre généralement une
                  meilleure expérience utilisateur. Les principales versions
                  d'Android incluent de nouvelles fonctionnalités et des
                  améliorations de performances, ainsi que des mises à jour de
                  sécurité importantes.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/clipboard-warnings"
                  >
                    <span className="Property__label___33tiu">
                      dispose de la fonction message d'avertissement du
                      Presse-papiers
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>
                        Honor X9c 5G{/* */} (
                        <a href="/fr/android-14">Android 14</a>)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Cette fonction de confidentialité vous avertit par une petite
                  notification lorsqu'une application copie des données de votre
                  presse-papiers.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/location-privacy"
                  >
                    <span className="Property__label___33tiu">
                      dispose d'options de confidentialité relatif à la
                      localisation
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>
                        Honor X9c 5G{/* */} (
                        <a href="/fr/android-14">Android 14</a>)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Cette fonction de confidentialité vous permet de partager
                  votre position approximative avec une application, au lieu de
                  votre localisation précise.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/camera-mic-access"
                  >
                    <span className="Property__label___33tiu">
                      dispose d'options de confidentialité pour la caméra et le
                      microphone
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>
                        Honor X9c 5G{/* */} (
                        <a href="/fr/android-14">Android 14</a>)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Vous pouvez activer ou désactiver l'accès d'une application à
                  la caméra ou au microphone de votre appareil. Il existe
                  également des icônes de notification pour vous montrer quand
                  une application utilise la caméra ou le microphone.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/mail-privacy"
                  >
                    <span className="Property__label___33tiu">
                      dispose de la protection de la confidentialité dans Mail
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <p className="Boolean__boolean___2EqLa Boolean__noDetail___1R5eJ">
                      <span className="Boolean__suggestion___3W-rc">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La protection de la confidentialité dans Mail est une fonction
                  intégrée à l'application de messagerie par défaut qui empêche
                  les expéditeurs d'utiliser des "pixels de suivi" pour savoir
                  si vous avez lu un e-mail. Elle permet également de cacher
                  votre adresse IP aux expéditeurs.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/theme-customization"
                  >
                    <span className="Property__label___33tiu">
                      dispose de la fonctionnalité personnalisation du thème
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>
                        Honor X9c 5G{/* */} (
                        <a href="/fr/android-14">Android 14</a>)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La personnalisation des thèmes vous permet de modifier
                  facilement l'apparence de l'interface utilisateur (IU). Par
                  exemple, en modifiant les couleurs du système ou les icônes
                  des applications.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/block-app-tracking"
                  >
                    <span className="Property__label___33tiu">
                      peut bloquer le suivi des applications
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <p className="Boolean__boolean___2EqLa Boolean__noDetail___1R5eJ">
                      <span className="Boolean__suggestion___3W-rc">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Certaines applications suivent votre activité même
                  lorsqu'elles ne sont pas utilisées. Cette fonction de
                  confidentialité vous permet de bloquer ce suivi, soit pour
                  toutes les applications, soit pour chaque application.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/block-cross-site-tracking"
                  >
                    <span className="Property__label___33tiu">
                      bloque le suivi intersite
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <p className="Boolean__boolean___2EqLa Boolean__noDetail___1R5eJ">
                      <span className="Boolean__suggestion___3W-rc">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Ce paramètre (dans le navigateur par défaut) vous permet de
                  bloquer les cookies et les données de suivi intersites afin de
                  protéger votre vie privée.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/on-device-machine-learning"
                  >
                    <span className="Property__label___33tiu">
                      dispose de l'apprentissage automatique sur l'appareil
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>
                        Honor X9c 5G{/* */} (
                        <a href="/fr/android-14">Android 14</a>)
                      </span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  L'apprentissage automatique sur l'appareil est utilisé pour
                  reconnaître les personnes, les lieux et autres dans
                  l'application Photos. Les algorithmes sont exécutés sur
                  l'appareil, où ces données sensibles sont ensuite conservées.
                  Les services concurrents téléchargent les photos et effectuent
                  cette opération sur leurs serveurs, ce qui nécessite le
                  partage de données personnelles.
                </div>
              </div>
            </div>
            <div className="Group__buttonContainer___1XtXa">
              <button aria-label="Load more properties in Système d'opération group">
                + Voir plus +
              </button>
            </div>
          </div>
        </div>
        <div id="group_battery" className="Group__group___1bHuf">
          <div className="Group__groupContent___34APs">
            <h2>
              <span className="Group__icon___1qsH3">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 24, height: 24, fill: "black" }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_battery</title>
                    <path d="M25.031 10.99h-21.042v9.996h21.042v-9.996zM4.989 19.987v-3.342l4.637-4.654h3.382l-7.967 7.996h-0.052zM14.421 11.99h3.383l-7.968 7.996h-3.382l7.967-7.996zM19.217 11.99h3.383l-7.968 7.996h-3.382l7.967-7.996zM24.012 11.99h0.019v3.375l-4.604 4.621h-3.382l7.967-7.996zM8.214 11.99l-3.225 3.236v-3.236h3.225zM20.839 19.987l3.192-3.203v3.203h-3.192zM30 11.985h-3.009v-3.013h-24.991v14.052h24.991v-3.040h2.963l0.046-7.999zM25.991 22.024h-22.991v-12.052h22.991v12.052zM28.96 18.985h-1.969v-6h2.003l-0.034 6z" />
                  </svg>
                </span>
              </span>
              Batterie
            </h2>
            <div className="Group__propertiesContainer___3-B5C">
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/battery-power"
                  >
                    <span className="Property__label___33tiu">
                      capacité de la batterie
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">6600 mAh</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "26%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La puissance de la batterie, ou capacité, représente la
                  quantité d'énergie que la batterie peut stocker. Une puissance
                  élevée peut être signe d'une durée de vie plus longue.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/cable-less"
                  >
                    <span className="Property__label___33tiu">
                      chargement sans fil
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Dispositif compatible avec un système de chargement standard
                  comme Qi. Pour charger l'appareil, tu le poses simplement sur
                  un support de chargement compatible.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/fast-charging"
                  >
                    <span className="Property__label___33tiu">
                      Prend en charge le chargement rapide
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Les technologies de chargement rapide, tels que Qualcomm's
                  Quick Charge ou MediaTek's Pump Express, sont utilisées pour
                  réduire le temps de charge d'un appareil. Par exemple, avec
                  Quick Charge 3.0, la batterie peut être chargée à 50% en juste
                  30 minutes.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/charging-speed"
                  >
                    <span className="Property__label___33tiu">
                      vitesse de chargement
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">66W</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Les vitesses de chargement sont exprimées en watts (W), une
                  mesure de la puissance électrique. Une puissance en watts
                  élevée permet un chargement plus rapide. Il est important
                  d'utiliser un chargeur compatible si l'on souhaite obtenir les
                  temps de chargement annoncés par les fabricants.{" "}
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/reverse-wireless-charging"
                  >
                    <span className="Property__label___33tiu">
                      dispose d'un système de recharge sans fil inversée
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  L'appareil peut être utilisé comme une station de recharge
                  sans fil pour alimenter d'autres gadgets.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/removeable-battery"
                  >
                    <span className="Property__label___33tiu">
                      doté(e) d'une batterie mobile
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La batterie peut être retiré et remplacée par l'utilisateur en
                  cas de pannne
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/black-and-white-screen"
                  >
                    <span className="Property__label___33tiu">
                      Possède un mode d'ultra-économie d'énergie
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <p className="Boolean__boolean___2EqLa Boolean__noDetail___1R5eJ">
                      <span className="Boolean__suggestion___3W-rc">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le dispositif peut économiser d'énergie en utilisant un écran
                  noir-et-blanc avec uniquement quelques icônes basiques pour
                  économiser d'énergie. Cela dit, s'il ne vous restez pas assez
                  d'énergie et n'est pas possible le charger à ce moment, vous
                  pouvez garder votre dispositif allumé pendant longtemps.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/battery-level-indication"
                  >
                    <span className="Property__label___33tiu">
                      dispose d'un indicateur de niveau de batterie
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Cet indicateur vous mettra au courant lorsque la batterie est
                  faible.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/battery-life-hours"
                  >
                    <span className="Property__label___33tiu">
                      Durée de vie de la batterie
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La durée de vie de la batterie (en utilisation) de l'appareil,
                  selon le fabricant. Avec une durée de vie plus longue de la
                  batterie, l'appareil nécessite d'être chargé moins souvent.
                </div>
              </div>
            </div>
            <div className="Group__buttonContainer___1XtXa">
              <button aria-label="Load more properties in Batterie group">
                + Voir plus +
              </button>
            </div>
          </div>
        </div>
        <div id="group_audio" className="Group__group___1bHuf">
          <div className="Group__groupContent___34APs">
            <h2>
              <span className="Group__icon___1qsH3">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 24, height: 24, fill: "black" }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_musical_note</title>
                    <path d="M12 2v22.625c-0.912-0.981-2.359-1.625-4-1.625-2.757 0-5 1.794-5 4s2.243 4 5 4 5-1.794 5-4c0-0.105-0.029-0.204-0.039-0.307 0.025-0.060 0.039-0.125 0.039-0.193v-19.5h16v15.625c-0.912-0.981-2.359-1.625-4-1.625-2.757 0-5 1.794-5 4s2.243 4 5 4 5-1.794 5-4c0-0.105-0.029-0.204-0.039-0.307 0.025-0.060 0.039-0.125 0.039-0.193v-22.5h-18zM8 30c-2.206 0-4-1.346-4-3s1.794-3 4-3 4 1.346 4 3-1.794 3-4 3zM25 28c-2.206 0-4-1.346-4-3s1.794-3 4-3 4 1.346 4 3-1.794 3-4 3zM13 6v-3h16v3h-16z" />
                  </svg>
                </span>
              </span>
              Audio
            </h2>
            <div className="Group__propertiesContainer___3-B5C">
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/headset-jack-35"
                  >
                    <span className="Property__label___33tiu">
                      équipé d'un connecteur pour brancher un micro-casque
                      3,5&nbsp;mm
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Vous pouvez utiliser un connecteur mini-jack standard pour
                  brancher vos écouteurs.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/stereo-speaker"
                  >
                    <span className="Property__label___33tiu">
                      doté(e) d'un haut-parleur stéréo intégré
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Les appareils munis de haut-parleurs stéréo produisent un son
                  venant à la fois de droite et de gauche par des canaux
                  indépendants, créant ainsi un son plus riche et une meilleure
                  expérience.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/bluetooth-aptx"
                  >
                    <span className="Property__label___33tiu">
                      possède aptX
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  aptX est un codec qui permet la transmission sans fil d'audio
                  via Bluetooth. Développée par Qualcomm, cette technologie
                  prend en charge l'audio 16 bits à un débit de 384 kbps.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/ldac"
                  >
                    <span className="Property__label___33tiu">
                      possède LDAC
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  LDAC est un codec développé par Sony pour l'audio Bluetooth.
                  Cette technologie peut atteindre un débit très élevé de 990
                  kbps, produisant ainsi de l'audio haute résolution. Elle peut
                  aussi s'adapter automatiquement à un débit plus bas de 330
                  kbps ou 660 kbps pour améliorer la stabilité.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/aptx-hd"
                  >
                    <span className="Property__label___33tiu">
                      possède aptX HD
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  aptX HD est un codec audio pour les appareils Bluetooth
                  développé par Qualcomm. Cette technologie prend en charge
                  l'audio haute qualité de 24 bits (à un débit de 576 kbps).
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/aptx-adaptive"
                  >
                    <span className="Property__label___33tiu">
                      possède aptX Adaptive
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  aptX Adaptive est un codec audio pour les appareils Bluetooth
                  développé par Qualcomm. Cette technologie possède un débit
                  variable (entre 279 kbps et 420 kbps), ce qui veut dire
                  qu'elle adapte le débit en fonction de la situation, comme
                  l'écoute en audio HD ou encore la réduction d'interférence
                  provenant d'autres appareils.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/aptx-lossless"
                  >
                    <span className="Property__label___33tiu">
                      avec aptX Lossless
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  aptX Lossless est un codec audio pour appareils Bluetooth
                  développé par Qualcomm. Il prend en charge l'audio haute
                  qualité 16-bit/44.1kHz (au débit maximum de 1200kbps). Ce
                  codec fournit un son de qualité CD sans perte de données.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/radio"
                  >
                    <span className="Property__label___33tiu">
                      doté(e) d'une fonction radio
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_no___1n175">✖</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Fonctionne comme une radio FM avec casque fourni
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/mic-count"
                  >
                    <span className="Property__label___33tiu">
                      nombre de microphones
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      <span className="Number__suggestion___2fPX9">
                        <svg
                          stroke="currentColor"
                          fill="currentColor"
                          strokeWidth={0}
                          viewBox="0 0 512 512"
                          height="1em"
                          width="1em"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M504 256c0 136.997-111.043 248-248 248S8 392.997 8 256C8 119.083 119.043 8 256 8s248 111.083 248 248zM262.655 90c-54.497 0-89.255 22.957-116.549 63.758-3.536 5.286-2.353 12.415 2.715 16.258l34.699 26.31c5.205 3.947 12.621 3.008 16.665-2.122 17.864-22.658 30.113-35.797 57.303-35.797 20.429 0 45.698 13.148 45.698 32.958 0 14.976-12.363 22.667-32.534 33.976C247.128 238.528 216 254.941 216 296v4c0 6.627 5.373 12 12 12h56c6.627 0 12-5.373 12-12v-1.333c0-28.462 83.186-29.647 83.186-106.667 0-58.002-60.165-102-116.531-102zM256 338c-25.365 0-46 20.635-46 46 0 25.364 20.635 46 46 46s46-20.636 46-46c0-25.365-20.635-46-46-46z" />
                        </svg>{" "}
                        Inconnu. Aide-nous en suggérant une valeur.
                      </span>
                    </p>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Un appareil qui a plus de micrphones, filtre mieux les bruits
                  de fond et améliore la qualité générale de l'enregistrement
                  sonore.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          id="group_connectivity_and_features"
          className="Group__group___1bHuf"
        >
          <div className="Group__groupContent___34APs">
            <h2>
              <span className="Group__icon___1qsH3">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 24, height: 24, fill: "black" }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_plus</title>
                    <path d="M25.192 6.808c-2.461-2.461-5.731-3.819-9.208-3.823-0.005 0-0.011 0-0.017 0-3.47 0-6.731 1.35-9.183 3.801-5.068 5.068-5.059 13.326 0.022 18.407 2.544 2.544 5.885 3.817 9.224 3.817 3.328 0 6.653-1.265 9.183-3.795 5.070-5.069 5.060-13.327-0.021-18.407zM24.507 24.508c-4.679 4.679-12.302 4.669-16.993-0.022s-4.701-12.314-0.022-16.993c2.263-2.263 5.272-3.508 8.476-3.508 0.005 0 0.010 0 0.016 0 3.21 0.004 6.229 1.258 8.501 3.53 4.691 4.691 4.701 12.313 0.022 16.993zM16.5 8.021h-1v7.479h-7.479v1h7.479v7.479h1v-7.479h7.479v-1h-7.479z" />
                  </svg>
                </span>
              </span>
              Fonctionnalités
            </h2>
            <div className="Group__propertiesContainer___3-B5C">
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/5g-support"
                  >
                    <span className="Property__label___33tiu">
                      Prend en charge la 5G
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Prend en charge la technologie 5G. Le réseau mobile de
                  cinquième génération offre plus de rapidité et moins de
                  latence que son prédécesseur de quatrième génération.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/wifi"
                  >
                    <span className="Property__label___33tiu">
                      version Wi-Fi
                    </span>
                  </a>
                  <div className="Value__value___qfSvr ranked">
                    <p className="Number__number___Mp4lk">
                      Wi-Fi 4 (802.11n), Wi-Fi 5 (802.11ac)
                    </p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "25%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La version Wi-Fi prise en charge par l'appareil.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/download"
                  >
                    <span className="Property__label___33tiu">
                      vitesse de téléchargement
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      2900 MBits/s{/* */} (
                      <a href="/fr/qualcomm-snapdragon-6-gen-1">
                        Qualcomm Snapdragon 6 Gen 1
                      </a>
                      )
                    </p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "28%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  C'est la vitesse maximale de téléchargement (download) prise
                  en charge. En réalité, cette valeur sera souvent plus basse
                  car elle sera impactée par d'autres facteurs (par exemple la
                  vitesse de ton réseau mobile/domestique).
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/upload"
                  >
                    <span className="Property__label___33tiu">
                      vitesse de téléchargement (upload)
                    </span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">
                      900 MBits/s{/* */} (
                      <a href="/fr/qualcomm-snapdragon-6-gen-1">
                        Qualcomm Snapdragon 6 Gen 1
                      </a>
                      )
                    </p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "12%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  C'est la vitesse maximale de téléchargement (upload) prise en
                  charge. En réalité, cette valeur sera souvent plus basse car
                  elle sera impactée par d'autres facteurs (par exemple la
                  vitesse de ton réseau mobile/domestique).
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/usb-type-c"
                  >
                    <span className="Property__label___33tiu">
                      Dispose de ports USB de Type-C
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Les ports USB de Type-C ont une prise réversible et peuvent
                  donc être branchés quelque soit leur orientation.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/usb-version"
                  >
                    <span className="Property__label___33tiu">version USB</span>
                  </a>
                  <div className="Value__value___qfSvr number">
                    <p className="Number__number___Mp4lk">2</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "45%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La nouvelle version USB est plus rapide et offre une meilleure
                  gestion de la puissance.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/nfc"
                  >
                    <span className="Property__label___33tiu">
                      doté(e) d'un périphérique NFC
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  La communication en champ proche (NFC en anglais) permet aux
                  appareils d'effectuer des transactions sans fil simples, tels
                  que des paiements mobiles. Note: cette fonctionnalité n'est
                  pas disponible sur tous les marchés.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/sim-cards"
                  >
                    <span className="Property__label___33tiu">cartes SIM</span>
                  </a>
                  <div className="Value__value___qfSvr ranked">
                    <p className="Number__number___Mp4lk">2 SIM</p>
                    <div className="Number__lineChart___6etoB">
                      <div
                        className="Number__one___CS3_V"
                        style={{ width: "35%" }}
                      />
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le nombre et le type de cartes SIM prises en charge.
                </div>
              </div>
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/fingerprint-scanner"
                  >
                    <span className="Property__label___33tiu">
                      Contient d'un lecteur d'empreintes digitales
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Le dispositif contient d'un lecteur d'empreintes digitales qui
                  identifie l'utilisateur.
                </div>
              </div>
            </div>
            <div className="Group__buttonContainer___1XtXa">
              <button aria-label="Load more properties in Fonctionnalités group">
                + Voir plus +
              </button>
            </div>
          </div>
        </div>
        <div id="group_other" className="Group__group___1bHuf">
          <div className="Group__groupContent___34APs">
            <h2>
              <span className="Group__icon___1qsH3">
                <span
                  className="VsIcon__icon___3m84T"
                  style={{ width: 24, height: 24, fill: "black" }}
                >
                  {/* Generated by IcoMoon.io */}
                  <svg
                    version="1.1"
                    xmlns="http://www.w3.org/2000/svg"
                    width={32}
                    height={32}
                    viewBox="0 0 32 32"
                  >
                    <title>_versus</title>
                    <path d="M16 29c-7.186 0-13.031-5.832-13.031-13s5.845-13 13.031-13 13.031 5.832 13.031 13-5.845 13-13.031 13zM16 4c-6.634 0-12.031 5.383-12.031 12s5.397 12 12.031 12 12.031-5.383 12.031-12-5.397-12-12.031-12zM23.329 16.541c-0.155-0.272-0.36-0.499-0.615-0.682s-0.546-0.33-0.873-0.441c-0.327-0.111-0.663-0.205-1.006-0.283-0.333-0.078-0.66-0.15-0.981-0.216-0.322-0.067-0.607-0.141-0.857-0.225s-0.452-0.191-0.607-0.324-0.233-0.305-0.233-0.516c0-0.177 0.044-0.319 0.133-0.424s0.197-0.186 0.324-0.241 0.269-0.091 0.424-0.108c0.155-0.017 0.299-0.025 0.432-0.025 0.421 0 0.787 0.080 1.098 0.241 0.31 0.161 0.482 0.469 0.516 0.923h2.245c-0.044-0.532-0.18-0.973-0.407-1.322s-0.513-0.629-0.857-0.84-0.735-0.36-1.173-0.449c-0.438-0.089-0.89-0.133-1.356-0.133s-0.92 0.042-1.364 0.125c-0.444 0.083-0.843 0.227-1.198 0.432-0.082 0.047-0.147 0.112-0.221 0.168l0.167-0.492h-2.345l-1.813 5.871h-0.033l-1.813-5.871h-2.478l2.944 8.599h2.628l1.862-5.501c0.038 0.139 0.086 0.271 0.155 0.387 0.155 0.261 0.36 0.477 0.615 0.649s0.546 0.31 0.873 0.416c0.327 0.105 0.663 0.197 1.006 0.274 0.843 0.177 1.5 0.355 1.971 0.532s0.707 0.444 0.707 0.798c0 0.211-0.050 0.385-0.15 0.524s-0.225 0.25-0.374 0.333c-0.15 0.083-0.316 0.144-0.499 0.183s-0.358 0.058-0.524 0.058c-0.233 0-0.457-0.028-0.674-0.083s-0.407-0.141-0.574-0.258c-0.166-0.116-0.302-0.266-0.407-0.449s-0.158-0.402-0.158-0.657h-2.245c0.022 0.577 0.152 1.056 0.391 1.439s0.543 0.69 0.915 0.923c0.371 0.233 0.796 0.399 1.272 0.499s0.965 0.15 1.464 0.15c0.488 0 0.967-0.047 1.439-0.141 0.471-0.094 0.89-0.258 1.256-0.491s0.663-0.541 0.89-0.923c0.227-0.383 0.341-0.857 0.341-1.422 0-0.4-0.077-0.735-0.233-1.007z" />
                  </svg>
                </span>
              </span>
              Autre
            </h2>
            <div className="Group__propertiesContainer___3-B5C">
              <div className="Property__property___1PAON">
                <div className="Property__valueContainer___14Alj">
                  <a
                    className="Property__propertyLabel___3GatO"
                    href="/fr/honor-x9c-5g/video-light"
                  >
                    <span className="Property__label___33tiu">
                      doté(e) d'une lampe vidéo
                    </span>
                  </a>
                  <div className="Value__value___qfSvr Value__boolean___SQx9h">
                    <div className="Boolean__boolean___2EqLa">
                      <span className="Boolean__boolean_yes___2ld0F">✔</span>
                      <span>Honor X9c 5G</span>
                    </div>
                  </div>
                </div>
                <div className="Property__explanationContainer___1qSm_">
                  Une lampe vidéo permet d'enregistrer une vidéo dans des
                  conditions de lumière basse comme le cas d'une fête.
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default SingleBody;
